import React, { useState, useEffect, useRef } from "react";

import { useLocation, useNavigate, useParams } from "react-router";
import Canvas from "../Canvas/Canvas";
import { CanvasContext } from "../Canvas/WorkflowCanvas";
import ProgressViewStore from "../../ProjectCanvas/ProgressViewStore";

import { send_request } from "../../../utils/Request";
import {GET_PRODUCTION_WORKFLOW, GET_SESSION_WORKFLOW} from "../Canvas/CanvasQueries";
import { send_request_graphql } from "../../../utils/Request";
import { extractNodes, extractEdges, isEdgeType } from "../../../utils/CanvasUtil";

// Custom Components
import TopGrid from "./TopGrid";
import { calculateTimeElapsedForLink, getColourFromStatus } from "./Util";
import SessionInfoModal from "./SessionInfoModal";
import SessionPolling from "./SessionPolling";

// MUI
import { styled } from "@mui/styles";
import { collectThreadMappingsWithStatus, drawPaths, invertThreadPath, removeOldThreadPaths, replaceOldThreadWithNew } from "./LoopSelectionAlgorithm";
import { StyledSkeleton } from "../DashboardComponents/CommonComponents";

const CanvasDiv = styled("div")(({ isSplitView }) => ({
    position: "relative",
    width: "100%",
    height: isSplitView ? "calc(100vh - 48px)" : "100vh",
}));

function WorkflowProgressView(props) {
    const { search } = useLocation();
    const navigate = useNavigate();

    // true/false whether it's a test session or not
    const isTestSession = Boolean((search && search === "?test=true") || props.isTest);

    const [loading, setLoading] = useState(false);
    const [canvasLoading, setCanvasLoading] = useState(false);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    // Define nodes & edges
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const [workflow, setWorkflow] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [isPolling, setIsPolling] = useState(true);

    const [modalData, setModalData] = useState({});

    const [showTerminateButton, setShowTerminateButton] = useState(false);

    const reactFlowWrapper = useRef(null);

    const [threadMapping, setThreadMapping] = useState({});
    const [loopPath, setLoopPath] = useState({});

    const [reRuns, setReRuns] = useState(null);
    const [isLatestRun, setIsLatestRun] = useState(true);
    const [loadReRun, setLoadReRun] = useState(false);

    const [showRerunMenu, setShowRerunMenu] = useState(false);
    const [draftVersion, setDraftVersion] = useState([]);
    const [productionVersion, setProductionVersion] = useState([]);

    const params = useParams();
    const id = props.id ? props.id : params.id;
    const project_session_id = props.project_session_id ? props.project_session_id : params.project_session_id;

    let threads = {};

    useEffect(async () => {
        resetStateForNewSessionSelected(); // Reset state here

        getWorkflowProgressData();
        getLatestDraftVersion(id);
        getLatestProductionVersion(id);
    }, [id, project_session_id]);


    // Function for resetting some of the state when a new progress view session
    // has been selected
    const resetStateForNewSessionSelected = () => {
        setModalData({});
        setThreadMapping({});
        setLoopPath({});
        setIsPolling(true);
        setCanvasLoading(true);
    }

    const getReruns = async () => {
        const url = `project-service/project/get-rerun-headers/${id}/${project_session_id}`;
        
        return await send_request(url, null, null, "GET")
          .then(res => {
            setReRuns(res.data);
            setLoading(false);
          })
          .catch(e => {
            console.log(e);
            setLoading(false);
          });
    
    }

    const findComponentSession = (currComp, sessions, type, path) => {
        if (!sessions["components"]) return;

        let sessionComps = sessions["components"];
        let relevantComps = sessionComps.filter((sC) => sC.componentId === currComp.id); // Grab only by component id

        // If it's not a loop or assign task
        if (currComp.data.type != "loop_through_list" && currComp.data.type != "sequential_form") {
            let session = findNonLoopOrAssignTaskSession(relevantComps, path);
            if (session) return session;
        } else if (currComp.data.type === "loop_through_list") {
            // Find Success End and store its thread info
           for (let i = 0; i < relevantComps.length; i++) {
                if (relevantComps[i].status != "SUCCESS") continue;
                let currSuccess = relevantComps[i];
                if (currSuccess.thread.threadId in path) return currSuccess;
            }
        } else if (currComp.data.type === "sequential_form") {
            // Go through the task sessions
            for (let i = 0; i < relevantComps.length; i++) {
                let currC = relevantComps[i];

                // If a thread doesn't exist and there are no current threads in the pool
                // Return it
                if (!currC.thread && Object.keys(threads).length === 0) return currC;

                // If the status is waiting or terminated, we check if both
                // the threadId and parentThreadID exist in the pool
                // If so, we add the mapping and return the component
                if (currC.status === "WAITING" || currC.status === "TERMINATED") {
                    if (currC.thread.threadId in threads && (currC.thread.parentThreadId in threads || currC.thread.parentThreadId === "null")) {
                        threads[currC.thread.threadId] = currC.thread.parentThreadId;
                        return currC;
                    }
                } else {
                    // Else, we know it must be success
                    // So we check if the waiting state thread ids are in the pool
                    if (
                        (currC.data.waitingStateThreadId in threads && (currC.data.waitingStateParentThreadId in threads || currC.data.waitingStateParentThreadId === "null")) ||
                        (currC.data.waitingStateThreadId === "null" && currC.data.waitingStateParentThreadId === "null")
                    ) {
                        threads[currC.thread.threadId] = currC.thread.parentThreadId;
                        return currC;
                    }
                }
            }

            // If we haven't found anything, we try to look in the normal case
            let session = findNonLoopOrAssignTaskSession(relevantComps, path);
            if (session) return session;
        }
    };

    // Handles altering a thread path from oldThread => newThread
    const handleAlterThreadPath = (oldThread, newThread) => {
        let currPath = { ...loopPath };

        // #1 - Remove any oldThread mapping
        let newPath = removeOldThreadPaths(oldThread, currPath);
        
        // #2 - Replace oldThread with newThread in threadIds
        let threadRemovedPath = replaceOldThreadWithNew(oldThread, newThread, newPath, "null");

        // #3 - Redraw path from newThread
        let newSelectedPath = drawPaths(threadMapping, newThread);
        const finalPath = { ...threadRemovedPath, ...newSelectedPath }; // Add new paths to old removed path

        setLoopPath(finalPath);
        handleLogic(sessionData, invertThreadPath(finalPath), isLatestRun); // Invert and set in data
    }

    const findNonLoopOrAssignTaskSession = (relevantComps, path) => {
        for (let i = 0; i < relevantComps.length; i++) {
            let currRelevantComp = relevantComps[i];
            if (!currRelevantComp.thread) return currRelevantComp;

            if (currRelevantComp.thread.threadId in path) {
                if (path[currRelevantComp.thread.threadId] === currRelevantComp.thread.parentThreadId) return currRelevantComp;
            }
        }
    };

    // returns true if we should display the terminate button
    const shouldDisplayTerminateButton = (comp) => {
        if (!comp || !comp.data || !comp.data.type) return;
        let type = comp.data.type;
        if (type === "sequential_form" || type === "form_section") setShowTerminateButton(true);
    };

    /**
     * Gets the general correct order of the components
     * We do this by looping through the sessions, finding the relevant component and
     * adding it to the reOrdered component list
     * @param {*} comps
     * @param {*} sessions
     * @returns
     */
    const getCorrectOrderOfComponents = (comps, sessions) => {
        let sessionComp = [...sessions.components];

        let links = [];
        let dbCompAndLinks =[];
        let visitedC = [];
        let reorderedComponents = [];

        for (let i = 0; i < sessionComp.length; i++) {
            let foundComp = null;

            for (let j = 0; j < comps.length; j++) {
                if (comps[j].id === sessionComp[i].componentId) {
                    foundComp = comps[j];
                    break;
                }
            }

            if (!foundComp || visitedC.includes(foundComp.id)) continue;
            reorderedComponents.push(foundComp);
            visitedC.push(foundComp.id);
        }

        // Loop over comps
        for (let i = 0; i < comps.length; i++) {
            if(comps[i].type==="dbNode")
            {
                dbCompAndLinks.push(comps[i]);
                continue;
            }
            if (isEdgeType(comps[i].type)) {
                if(comps[i].data && comps[i].data.hasDb)
                    dbCompAndLinks.push(comps[i])
                else
                    links.push(comps[i]);
                continue;
            }
            if (visitedC.includes(comps[i].id)) continue;

            reorderedComponents.push(comps[i]);
            visitedC.push(comps[i].id);
        }

        return {
            comps: reorderedComponents,
            links: links,
            dbCompAndLinks:dbCompAndLinks
        };
    };
    /**
     * Adds colour to the components (success, waiting, terminated, etc)
     * @param {*} comps
     * @param {*} sessions
     * @returns
     */
    const addColourToComponents = (comps, sessions, path, isLatestReRun, passedModalData) => {
        if (!comps || !sessions) return;
        let correctOrder = getCorrectOrderOfComponents(comps, sessions);

        let sessionComponents = correctOrder.comps;

        // Handle Components
        for (let i = 0; i < sessionComponents.length; i++) {
            let currComp = sessionComponents[i];

            if (sessions.status === "WAITING" || sessions.hasWaitingStatus) shouldDisplayTerminateButton(currComp);

            // Try to find the component session
            let currCompSession = findComponentSession(currComp, sessions, "component", path);

            // Set the type of the node and the initial grey colour
            sessionComponents[i].type = "sessionComponent";
            sessionComponents[i].data.colour =  "#C4C4C4DE" ;
            sessionComponents[i].data.baseColor = "#C4C4C4DE" ;

            // If the session exists, it means it has been run
            // Therefore, we can set the colour and the session Data
            if (currCompSession) {
                sessionComponents[i].data.colour = getColourFromStatus(currCompSession.status) ;
                sessionComponents[i].data.baseColor = getColourFromStatus(currCompSession.status) ;
                sessionComponents[i].data.sessionData = currCompSession;

                sessionComponents[i].data.modalData = modalData; // Set the modal data
                sessionComponents[i].data.allSessionData = sessions;
            } else {
                sessionComponents[i].data.modalData = {}; // Set the modal data
                sessionComponents[i].data.sessionData = null;
            }

            sessionComponents[i].data.setModalData = (value) => {
                setModalData(value);
                setShowRerunMenu(false);
            };

            // Update the current component modal panel data
            if ((modalData && modalData.componentId === sessionComponents[i].id) ||
                (passedModalData && passedModalData.componentId === sessionComponents[i].id)) {
                setModalData({ ...sessionComponents[i].data.sessionData, hasRun: true });
            }
        }

        // Get the coloured links
        let compLinks = getColouredComponentLinks(correctOrder.links, sessionComponents);

        // Add the links to the components and return them
        sessionComponents = [...sessionComponents, ...compLinks,...correctOrder.dbCompAndLinks];

        return sessionComponents;
    };

    /**
     * Get the coloured components from the sessions for the links between components
     * We find the source component of the link and set that colour from the source component
     * @param {*} links
     * @param {*} sessions
     * @returns
     */
    const getColouredComponentLinks = (links, sessions) => {
        let compLinks = [];

        // Handle links
        for (let i = 0; i < links.length; i++) {
            let newLink = { ...links[i] };

            // We did this because "stroke" is read-only
            let sty = { ...newLink["style"] };
            newLink["style"] = sty;

            // Set to default colour
            newLink.style.stroke = "#868686";
            newLink.animated = true;

            let foundSession = null;
            for (let j = 0; j < sessions.length; j++) {
                if (newLink.source === sessions[j].id) {
                    foundSession = sessions[j];
                    break;
                }
            }

            if (foundSession) {
                newLink.style.stroke = foundSession.data.colour;
                if (foundSession.data.colour != "#C4C4C4DE") newLink.animated = false;
                newLink["label"] = calculateTimeElapsedForLink(newLink, sessions); // Add time label to link if able
            }

            compLinks.push(newLink);
        }

        return compLinks;
    };

    const getWorkflowData = async (data) => {
        const url = `project-service/graphql/project/${id}`;

        return await send_request_graphql(url, GET_SESSION_WORKFLOW(id, data.projectVersion))
            .then((res) => {
                ProgressViewStore.setWorkflow(res.data.projectByIdAndVersion); //Load diagram into the store
                setWorkflow(res.data.projectByIdAndVersion); //set diagram into the state
                return res.data.projectByIdAndVersion;
            })
            .then((projectData) => {
                drawPathAndHandleLogic(data, projectData.components);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const drawPathAndHandleLogic = (sessionData, projectComps, passedModalData) => {
        let mapping = collectThreadMappingsWithStatus(sessionData.components, projectComps);
        let randomPath = drawPaths(mapping, "null");

        setThreadMapping(mapping);
        setLoopPath(randomPath);

        let path = invertThreadPath(randomPath); // Return inverted path
        handleLogic(sessionData, path, true, passedModalData);
    }

    const handleLogic = (data, path, isLatestReRun, passedModalData) => {
        let convertedTypes = addColourToComponents(ProgressViewStore.canvas, data, path, isLatestReRun, passedModalData);
        if (convertedTypes) {
            setNodes(extractNodes(convertedTypes));
            setEdges(extractEdges(convertedTypes));
        }       
        threads = {};
    }

    const getLatestDraftVersion = async (projectId) => {
        await send_request(`project-service/project/last-version/${projectId}`, "", "", "get").then((res) => {
                if (!res || !res.data) return;
                let {draftVersion} = res.data;
                if (draftVersion) {
                   setDraftVersion(draftVersion);
                }
            }
        )
    };

    const getLatestProductionVersion = (projectId) => {
        send_request_graphql(`project-service/graphql/project/${projectId}`, GET_PRODUCTION_WORKFLOW(projectId))
            .then( (response) => {
                if (!response ||!response.data || !response.data.publishedProjectById) return;
                let productionVersion = response.data.publishedProjectById.version;
                if (productionVersion) {
                    setProductionVersion(productionVersion);
                }

            });
    };
    const getWorkflowProgressData = async () => {

        threads = {};
        setSessionData({});

        setNodes([]);
        setEdges([]);

        setIsLatestRun(true);
        setShowTerminateButton(false);

        // Get the session data
        let url = `project-service/project/get_log/${id}/${project_session_id}`;

        // If it's a test, fetch the test logs
        if (isTestSession) url = `project-service/test/get_test_log/${id}/${project_session_id}`;

        return await send_request(url, "", {}, "")
            .then((res) => {
                setSessionData(res.data);
                return res.data;
            })
            .then(async (data) => {
                await getWorkflowData(data);
            })
            .then(async () => {
                await getReruns();
                setCanvasLoading(false); // Set canvas loading to false
            })
            .catch((e) => {
                window.location.href = `/project/logs/${id}`; // Go back on error
                console.log(e);
            });
    };

    const handleCloseRerunModal = () => {
        setModalData({});
        setShowRerunMenu(false);
    };
    return ((!canvasLoading && workflow && sessionData && reRuns) ? (
            <>
                <CanvasContext.Provider
                    value={{
                        value: [[], () => {}],
                        modeInfo: ["PROGRESSVIEW", () => {}],
                        currentSelected: modalData,
                        workflow: ProgressViewStore.canvas,
                    }}
                >
                    <CanvasDiv ref={reactFlowWrapper} isSplitView={props.isSplitView}>
                        {isPolling && 
                            <SessionPolling
                                projectId={id}
                                sessionId={project_session_id}
                                isPolling={isPolling}
                                setIsPolling={setIsPolling}
                                sessionData={sessionData}
                                drawPathAndHandleLogic={drawPathAndHandleLogic}
                                workflow={workflow}
                                modalData={modalData}
                                setModalData={setModalData}
                                setSessionData={setSessionData}
                                isSplitView={props.isSplitView}
                            />
                        }
                        <TopGrid
                            workflow={workflow}
                            sessionData={sessionData}
                            projectId={id}
                            sessionId={project_session_id}
                            getSessionData={getWorkflowProgressData}
                            setModalData={setModalData}
                            isTest={isTestSession}
                            testId={sessionData && sessionData.projectTestId}
                            showTerminateButton={showTerminateButton}
                            setLoadReRun={setLoadReRun}
                            isLatestRun={isLatestRun}
                            setIsLatestRun={setIsLatestRun}
                            reRuns={reRuns}
                            draftVersion={draftVersion}
                            productionVersion={productionVersion}
                            setSessionData={(newSession, isLatestReRun) => {
                                setSessionData(newSession);
                                handleLogic(newSession, invertThreadPath(loopPath), isLatestReRun);
                            }}
                            isPolling={isPolling}
                            setIsPolling={setIsPolling}
                            isSplitView={props.isSplitView}
                            navigate={navigate}
                        />
                        {modalData.componentId != null && (
                            <SessionInfoModal 
                                projectId={id}
                                modalData={modalData} 
                                setModalData={setModalData} 
                                sessionId={project_session_id}  
                                getWorkflowProgressData={getWorkflowProgressData} 
                                isTest={isTestSession}
                                projectTestId={sessionData.projectTestId}
                                sessionData={sessionData}
                                setSessionData={(newSession, isLatestReRun) => {
                                    setSessionData(newSession);
                                    handleLogic(newSession, invertThreadPath(loopPath), isLatestReRun);
                                }}
                                handleAlterThreadPath={handleAlterThreadPath}
                                isLatestRun={isLatestRun}
                                reRuns={reRuns}
                                setIsLatestRun={setIsLatestRun}
                                setLoadReRun={setLoadReRun}
                                loadReRun={loadReRun}
                                isShowRerunMenu={showRerunMenu}
                                setShowRerunMenu={setShowRerunMenu}
                                nodes={nodes}
                                reactFlowInstance={reactFlowInstance}
                                isSplitView={props.isSplitView}
                            />
                        )}
                        <Canvas 
                            mode={"PROGRESSVIEW"} 
                            isTemplate={false} 
                            nodes={nodes} 
                            setNodes={setNodes} 
                            edges={edges} 
                            setEdges={setEdges} 
                            reactFlowWrapper={reactFlowWrapper}
                            reactFlowInstance={reactFlowInstance}
                            setReactFlowInstance={setReactFlowInstance}
                            handleCloseRerunModal={handleCloseRerunModal}
                        />
                    </CanvasDiv>
                </CanvasContext.Provider>
            </>
        ) : (
            <StyledSkeleton variant="rectangular" width="100%" height={props.isSplitView ? "calc(-48px + 100vh)" : "100vh"} />
        )
    );
}

export default WorkflowProgressView;
