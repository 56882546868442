import FormBuilderContainer from "../FormBuilder/FormBuilderContainer";
import SequentialFormContainer from "../SequentialForm/SequentialFormContainer";

// Group icons
import GradeIcon from '@mui/icons-material/Grade';
import CodeIcon from '@mui/icons-material/Code';
import WebhookIcon from '@mui/icons-material/Webhook';
import StorageIcon from '@mui/icons-material/Storage';
import AlarmIcon from '@mui/icons-material/Alarm';
import DescriptionIcon from '@mui/icons-material/Description';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TableChartIcon from '@mui/icons-material/TableChart';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RouteIcon from '@mui/icons-material/Route';
import AIGroupIcon from './AIGroupIcon'

export const ComponentGroups = [
  {
    title: "Favourites",
    description: "Most popular and common components",
    icon: <GradeIcon sx={{ color: "rgba(255, 183, 77, 1)" }} />,
    components: ["sequential_form", "branch_paths", "email", "form", "session_variables"]
  },
  {
    title: "Artificial Intelligence",
    description: "AI to perform actions and tasks",
    icon: <AIGroupIcon/>,
    components: ["ai_assistant"]
  },
  {
    title: "API",
    description: "Receive webhooks and make API requests",
    icon: <WebhookIcon />,
    components: ["webhook", "api_request_and_response", "api_export"]
  },
  {
    title: "Code",
    description: "Write and run code",
    icon: <CodeIcon />,
    components: ["code"]
  },
  {
    title: "Databases",
    description: "Create, update and query a Workflow86 database",
    icon: <StorageIcon />,
    components: ["create_database_record", "query_database_record", "update_database_record"]
  },
  {
    title: "Date and Time",
    description: "Trigger workflows at a particular date and time, delay by time",
    icon: <AlarmIcon />,
    components: ["schedule_start", "time_delay"]
  },
  {
    title: "Documents",
    description: "Generate documents and PDFs",
    icon: <DescriptionIcon />,
    components: ["document_editor"]
  },
  {
    title: "Email",
    description: "Trigger a workflow from an email, send emails",
    icon: <EmailOutlinedIcon />,
    components: ["email", "trigger_via_email"]
  },
  {
    title: "Forms and Tasks",
    description: "Create forms and tasks to be completed by users",
    icon: <CheckBoxIcon />,
    components: ["form", "sequential_form", "form_section"]
  },
  {
    title: "Logic",
    description: "Create logic if-this-then-that statements",
    icon: <DeviceHubIcon />,
    components: ["conditional_logic", "conditional_workflow"]
  },
  {
    title: "Paths and loops",
    description: "Control the path taken when a workflow runs, run loops",
    icon: <AccountTreeIcon />,
    components: ["branch_paths", "conditional_workflow", "loop_through_list", "merge_workflow"]
  },
  {
    title: "Spreadsheets",
    description: "Trigger workflows from spreadsheets, create spreadsheets",
    icon: <TableChartIcon />,
    components: ["bulk_assessment"]
  },
  {
    title: "Tools and utilities",
    description: "Tools to transform and manipulate data, perform calculations and more",
    icon: <HomeRepairServiceIcon />,
    components: ["calculator", "list_transform", "session_variables", "parse_json"]
  },
  {
    title: "Integrations",
    description: "Pre-built integrations with external apps and tools",
    icon: <ExitToAppIcon />,
    components: ["trigger_from_zapier", "trigger_a_zap", "slack"]
  },
  {
    title: "Workflow connections",
    description: "Trigger and connect to other workflows",
    icon: <RouteIcon />,
    components: ["trigger_from_a_workflow", "trigger_another_workflow"]
  },
];

export const ComponentRegistry = [
  {
    name: "ai_assistant",
    displayName: "AI Assistant",
    description: "Use AI to perform a task or action and return an output",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/ai_assistant.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#944BA8",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false,
    hidden:false,
    isExcluded:false
  },
  {
    name: "api_export",
    displayName: "API Export",
    description: "Make a HTTP POST to an API endpoint",
    // discoveryEndpoint: "component-service/api-export/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/api_export.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#788ac8",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "api_request_and_response",
    displayName: "API Request and Response",
    description: "Make an API request and parse the data from the response",
    // discoveryEndpoint: "component-service/apirequestandresponse/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/api_request_and_response.svg",
    stateless: false,
    interactive: true,
    baseColor: "#2a4cc2",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "sequential_form",
    displayName: "Assign Task",
    description: "Assign someone a task to complete and run the next component when that task is completed",
    // discoveryEndpoint: "component-service/sequential_form/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/sequential_form.svg",
    sidebarLogo: "/svgs/sequential_form.svg",
    stateless: false,
    interactive: true,
    action: (CanvasNodeWidget) => {
      SequentialFormContainer.diagramEngine =
        CanvasNodeWidget.props.diagramEngine;
      SequentialFormContainer.node = CanvasNodeWidget.props.node;
      CanvasNodeWidget.props.navigate(
        window.location.pathname +
          `/sequential_form/${CanvasNodeWidget.props.node.extras.component_id ||
            ""}`
      );
    },
    tier: "FREE",
    baseColor: "#2670ff",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },
  {
    name: "branch_paths",
    displayName: "Branch Paths",
    description: "Branch workflow into Conditional Paths",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/branch_paths.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#000000",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: true,
    isExcluded:false
  },
  {
    name: "calculator",
    displayName: "Calculator",
    description: "Perform a calculation",
    // discoveryEndpoint: "component-service/calculator/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/calculator.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#ff7e7e",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "code",
    displayName: "Run Code",
    description: "Run Javascript code",
    // discoveryEndpoint: "component-service/code/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/code.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#000000",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },
  {
    name: "conditional_logic",
    displayName: "Conditional Logic",
    description: "Run a logic statement to set the value of a variable/placeholder",
    // discoveryEndpoint: "component-service/conditional-logic/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/conditional_logic.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#9cc5c5",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "conditional_workflow",
    displayName: "Conditional Path",
    description: "Control whether the workflow continues to the next action or stops based on a logic statement",
    // discoveryEndpoint: "component-service/conditional-workflow/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/conditional_workflow.svg",
    stateless: false,
    interactive: true,
    fromWorkflowPath:false,
    tier: "FREE",
    baseColor: "#FF7E3E",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false

  },
 {
    name: "conditional_workflow_path",
    displayName: "Conditional Workflow Paths",
    description: "Create paths that run based on a conditional statement",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/conditional_workflow_path.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#868686",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false
  },

  {
    name: "create_database_record",
    displayName: "Create Database Record",
    description: "Create a new row/record in a database to store information or data",
    // discoveryEndpoint: "component-service/create_database_record/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/create_database_record.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#E10050",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "knowledge_bank",
    displayName: "Content Library",
    description: "Store content as variables",
    // discoveryEndpoint: "component-service/knowledge-bank/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/knowledge.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#a880e8",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "document_editor",
    displayName: "Document Editor",
    description: "Generate word or PDF documents from a template",
    // discoveryEndpoint: "component-service/document-editor/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/report.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#ed6696",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "email",
    displayName: "Email",
    description: "Send an email",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/email.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#ffb74d",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "form",
    displayName: "Form",
    description: "Online form which triggers a workflow when submitted",
    // discoveryEndpoint: "component-service/form/placeholder",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/form.svg",
    sidebarLogo: "/svgs/form.svg",
    stateless: false,
    interactive: true,
    action: (CanvasNodeWidget) => {
      FormBuilderContainer.diagramEngine = CanvasNodeWidget.props.diagramEngine;
      FormBuilderContainer.node = CanvasNodeWidget.props.node;
      CanvasNodeWidget.props.navigate(
        window.location.pathname +
          `/formbuilder/${CanvasNodeWidget.props.node.extras.component_id ||
            ""}`
      );
    },
    tier: "FREE",
    baseColor: "#7ac0f8",
    isTrigger: true,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },
  {
    name: "form_section",
    displayName: "Form Section",
    description: "Redirect the user to another form section when a previous form or task has been submitted",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/form.svg",
    sidebarLogo: "/svgs/form.svg",
    stateless: false,
    interactive: true,
    action: (CanvasNodeWidget) => {
      SequentialFormContainer.diagramEngine =
        CanvasNodeWidget.props.diagramEngine;
      SequentialFormContainer.node = CanvasNodeWidget.props.node;
      CanvasNodeWidget.props.navigate(
        window.location.pathname +
          `/form_section/${CanvasNodeWidget.props.node.extras.component_id ||
            ""}`
      );
    },
    tier: "FREE",
    baseColor: "#7ac0f8",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },
  {
    name: "list_transform",
    displayName: "List Transform",
    description: "Transform and manipulate list type data",
    // discoveryEndpoint: "component-service/list-transform/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/list_transform.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#48a80f",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "loop_through_list",
    displayName: "Loop Through List",
    description: "Split a list into individual values and start a parallel run of all further components for each individual value",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/loop_through_list.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#48A80F",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "merge_workflow",
    displayName: "Merge Workflow",
    description: "Merge multiple workflow paths together and trigger a component when one or more connections have been completed",
    // discoveryEndpoint: "component-service/workflow_data/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/merge_workflow.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#ff3d00",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "parse_json",
    displayName: "Parse JSON",
    description: "Parse a JSON into placeholder values",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/parse_json.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#001356",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: true,
    isExcluded:true
  },
  // {
  //   name: "data_bank",
  //   displayName: "Query Spreadsheet",
  //   description: "Query the data in an uploaded spreadsheet",
  //   // discoveryEndpoint: "component-service/data_bank/placeholder",
  //   hasInput: true,
  //   hasOutput: true,
  //   logo: "/svgs/spreadsheet.svg",
  //   stateless: false,
  //   interactive: true,
  //   tier: "FREE",
  //   baseColor: "#55a77a",
  //   isTrigger: false,
  //   hasAccessControl: false
  // },
  {
    name: "query_database_record",
    displayName: "Query Database",
    description: "Query a database and return all values in a column or return values from a column based on a conditional statement",
    // discoveryEndpoint: "component-service/query_database_record/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/query_database.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#E10050",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  // {
  //   name: "workflow_data",
  //   displayName: "Query Workflow Data",
  //   description: "Query the data collected from a workflow",
  //   // discoveryEndpoint: "component-service/workflow_data/placeholder",
  //   hasInput: true,
  //   hasOutput: true,
  //   logo: "/svgs/workflow_data.svg",
  //   stateless: false,
  //   interactive: true,
  //   tier: "FREE",
  //   baseColor: "#09347B",
  //   isTrigger: false,
  //   hasAccessControl: false
  // },
  {
    name: "schedule_start",
    displayName: "Schedule Start",
    description: "Start this workflow a certain time or date",
    // discoveryEndpoint: "component-service/schedule-start/placeholder",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/schedule_start.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#1D7573",
    isTrigger: true,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "slack",
    displayName: "Send Slack Message",
    description: "Send a Slack message",
    // discoveryEndpoint: "component-service/slack/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/slack.svg",
    stateless: false,
    interactive: true,
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "session_variables",
    displayName: "Session Variables",
    description: "Create variables/placeholders that can be used in the rest of the workflow",
    // discoveryEndpoint: "component-service/code/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/session_variables.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#000000",
    isTrigger: false,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },

  {
    name: "time_delay",
    displayName: "Time Delay",
    description: "Delay the next action until a certain time or date",
    // discoveryEndpoint: "component-service/time-delay/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/time_delay.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#67C9D3",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "trigger_a_zap",
    displayName: "Trigger a Zap",
    description: "Trigger a zap in Zapier",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/zapier-icon.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#FF4A00",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "trigger_from_zapier",
    displayName: "Trigger from Zapier",
    description: "Trigger a workflow from a zap in Zapier",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/zapier-icon.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#FF4A00",
    isTrigger: true,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "trigger_another_workflow",
    displayName: "Trigger another Workflow",
    description: "Trigger a separate workflow from this workflow",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/trigger_another_workflow.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#868686",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "trigger_from_a_workflow",
    displayName: "Trigger from a Workflow",
    description: "Trigger this workflow when another workflow is run",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/trigger_from_a_workflow.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#2C2C2C",
    isTrigger: true,
    hasAccessControl: false,
    isExcluded:true
  },
  {
    name: "trigger_via_email",
    displayName: "Trigger via Email",
    description: "Trigger this workflow when an email is received at a specific email address",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/trigger_via_email.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#FFB800",
    isTrigger: true,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "update_database_record",
    displayName: "Update Database Record",
    description: "Update the values in a row/record in a database",
    // discoveryEndpoint: "component-service/update_database_record/placeholder",
    hasInput: true,
    hasOutput: true,
    logo: "/svgs/update_database_record.svg",
    stateless: false,
    interactive: true,
    baseColor: "#E10050",
    isTrigger: false,
    hasAccessControl: false,
    isExcluded:false
  },
  {
    name: "bulk_assessment",
    displayName: "Upload Spreadsheet",
    description: "Upload a spreadsheet and trigger a workflow session for each row",
    // discoveryEndpoint: "component-service/bulk_assessment/placeholder",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/bulk_assessment.svg",
    sidebarLogo: "/svgs/bulk_assessment.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#5bc4b0",
    isTrigger: true,
    hasAccessControl: true,
    isAlwaysPublic: false,
    isExcluded:false
  },
  {
    name: "webhook",
    displayName: "Webhook Import",
    description: "Trigger a workflow when a webhook is received",
    // discoveryEndpoint: "component-service/webhook/placeholder",
    hasInput: false,
    hasOutput: true,
    logo: "/svgs/webhook.svg",
    stateless: false,
    interactive: true,
    tier: "FREE",
    baseColor: "#8add9e",
    isTrigger: true,
    hasAccessControl: true,
    isAlwaysPublic: true,
    isExcluded:false
  },
  {
    name: "UI.dbIcon",
    displayName: "Database",
    description: "A Workflow86 database",
    logo: "/svgs/database.svg",
    stateless: false,
    interactive: false,
    tier: "FREE",
    baseColor: "#1C1B1F",
    hasAccessControl: true,
    isAlwaysPublic: true,
    newTabUrl :"/databases/database_management",
    hidden:true,
    isExcluded:false
  }
];

export default ComponentRegistry;

function createFactory(ComponentRegistry) {
  const node_factory = {};
  ComponentRegistry.forEach((registered_node) => {
    node_factory[registered_node.name] = {
      hidden:registered_node.hidden,
      name: registered_node.name,
      logo: registered_node.logo,
      sidebarLogo: registered_node.sidebarLogo,
      description: registered_node.description,
      isInteractive: registered_node.interactive,
      displayName: registered_node.displayName,
      tier: registered_node.tier,
      baseColor: registered_node.baseColor,
      isTrigger: registered_node.isTrigger,
      hasAccessControl: registered_node.hasAccessControl,
      isAlwaysPublic: registered_node.isAlwaysPublic,
      hasOutput: registered_node.hasOutput,
      hasInput: registered_node.hasInput,
      newTabUrl: registered_node.newTabUrl,
      fromWorkflowPath:registered_node.fromWorkflowPath,
      isExcluded:registered_node.isExcluded
    };
  });
  return node_factory;
}

export const ComponentFactory = createFactory(ComponentRegistry);
