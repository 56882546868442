import "./Component.css";
import { withStyles } from "@mui/styles";
import {
  Grid,
  Icon,
  List,
  Radio,
  Select,
  ListItem,
  Snackbar,
  Checkbox,
  MenuItem,
  IconButton,
  ListItemText,
  TextField as TextFieldUI,
  InputAdornment,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { toJS } from "mobx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import React, { Component } from "react";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Calendar from "./FormComponents/Calendar";
import Table from "../FormBuilder/FormComponents/Table/Table";
import TextEditor from "../TextEditor/TextEditor";
import MentionEditor from "../TextEditor/MentionEditor";
import FormRequired from "./FormConditionals/FormRequired";
import FormReadOnly from "./FormConditionals/FormReadOnly";
import FormVisibility from "./FormConditionals/FormVisibility";
import SequentialFormContainer from "./SequentialFormContainer";
import FormPredertermine from "./FormConditionals/FormPredertermine";
import ChoiceQuestion from "../FormBuilder/ConfigComponents/ChoiceQuestion";
import VideoEmbed from "../FormBuilder/ConfigComponents/VideoEmbed";
import TextField from "vardogyir-ui/TextField";
import Tooltips from "vardogyir-ui/Tooltips";
import Theme from "vardogyir-ui/Theme";
import Button from "@mui/material/Button";
import ButtonGroup from "vardogyir-ui/ButtonGroup";
import FormRequiredStore from "./FormConditionals/FormConditionStores/FormRequiredStore";
import FormReadOnlyStore from "./FormConditionals/FormConditionStores/FormReadOnlyStore";
import FormVisibilityStore from "./FormConditionals/FormConditionStores/FormVisibilityStore";
import FormPredertermineStore from "./FormConditionals/FormConditionStores/FormPredertermineStore";
import Popover from "@mui/material/Popover";
import SearchIcon from "@mui/icons-material/Search";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import uuid from "uuid";
import ChipInput from "../ChipInput/ChipInput";
import FormMentionEditor from "../Component/FormMentionEditor";
import RepeatableSection from "../FormBuilder/FormComponents/Table/RepeatableSection";
import ErrorIcon from "@mui/icons-material/Error";
import {FROM_DB, FROM_LIST} from "../../utils/FormUtil";

const styles = (theme) => ({
  textroot: {
    width: "100%",
    margin: "auto",
  },
  formAreaInput: {
    padding: "8px!important",
  },
  insertPlaceholderButton: {
    cursor: "pointer",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    "&:hover": {
      color: "#2196F3",
    },
    paddingTop: "4px",
  },
  searchableSelect: {
    textAlign: "center",
  },
  textFieldUI: {
    marginTop: 16,
    width: "90%",
    "& > div": {
      height: 40,
    },
  },
  filePreviewURL: {
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  filePreviewDescription: {
    fontSize: 12,
    color: "#868686",
    marginTop: 8,
  },
  centerGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  warningText:{
    fontSize:"12px"
  },
  warningGrid:{
    borderRadius: "4px",
    border: "1px solid #FFB74D",
    background: "#FFEACB",
  },
  warningIcon:{
    color: "#FFB74D", 
    margin: "8px" 
  }
});

const columnId = uuid();

const newColumn = {
  id: columnId,
  type: "text",
  columnName: "Column 1",
  question: "Question 1",
  description: "",
  options: [
    {
      id: uuid(),
      value: "",
      label: "",
    },
  ],
  dtFormat: {
    outputFormat: "dd/MM/YYYY kk:mm",
    timezone: "GMT+10",
  },
  required: false,
};

const tableCells = {};
tableCells[columnId] = [
  {
    id: "row1",
    isRequired: false,
    isDisabled: false,
    isReadOnly: false,
    defaultValue: "",
    rawValue: "",
  },
  {
    id: "row2",
    isRequired: false,
    isDisabled: false,
    isReadOnly: false,
    defaultValue: "",
    rawValue: "",
  },
  {
    id: "row3",
    isRequired: false,
    isDisabled: false,
    isReadOnly: false,
    defaultValue: "",
    rawValue: "",
  },
];

const initialRowLabels = [
  {
    rowNumber: "1",
    rowLabel: "",
    rowKey: "row1",
  },
  {
    rowNumber: "2",
    rowLabel: "",
    rowKey: "row2",
  },
  {
    rowNumber: "3",
    rowLabel: "",
    rowKey: "row3",
  },
];

class ComponentConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: false,
      requiredText: "",
      visibility: true,
      visibilityText: "",
      readonly: false,
      readonlyText: "",
      comment: false,
      autoAnswer: "",
      auto_answer: "",
      notificationEmails: {
        emailList: [],
        shouldNotify: false,
      },
      question_description_default: null,
      question_text_default: null,
      key: "",
      dataAuto: [],
      dataVisibility: [],
      dataReadOnly: [],
      dataRequired: [],
      dataComment: [],
      advancedExpand: false,
      answer_list: [["", ""]],
      answer_data_default: null,
      rte_answer_data_default:null,
      drop_down: "",
      checkKey: false,
      valueError: "",
      tableErrors: "",
      list_value_restrict: 0,
      list_min: 0,
      list_max: 0,
      outputFormat: "",
      timezone: "UTC",
      updateState: false,
      showDynamicFormError: false,
      labelColumnId: "",
      valueColumnId: "",
      numRows: 3,
      exportType: "columnsAsLists",
      exportFormat: "JSON",
      columns: [newColumn],
      tableCells: tableCells,
      rowLabels: initialRowLabels,
      contentBlockPlaceholders: [],
      availablePlaceholders: toJS(this.props.availablePlaceholders),
      file_type: "pdf",
      size: "RESPONSIVE",
      height: "",
      width: "",
      embedType: "URL",
      url: "",
      snippet: "",
    };
    this.handleChangeOutput = this.handleChangeOutput.bind(this);
    this.handleChangeTimezone = this.handleChangeTimezone.bind(this);
    this.handlePoppair = this.handlePoppair.bind(this);
    this.handleAddpair = this.handleAddpair.bind(this);
    this.handleAnswerpair = this.handleAnswerpair.bind(this);
    this.handleReplaceAnswerList = this.handleReplaceAnswerList.bind(this);
    this.questionMentionInstance = React.createRef();
    this.questionDescnMentionInstance = React.createRef();
    this.contentBlockTextEditorInstance = React.createRef();
    this.inputPretermineInstance = React.createRef();
  }

  componentDidMount() {
    if (toJS(SequentialFormContainer.editTarget.config)) {
      const data = toJS(SequentialFormContainer.editTarget);

      this.setState({
        advancedExpand: true,
      });

      this.setState((state) => {
        return SequentialFormContainer.editTarget.config;
      });
      if (data.config.list_max > 0 && data.config.list_min > 0)
        return this.setState({ list_value_restrict: 3 });
      else if (data.config.list_min > 0)
        return this.setState({ list_value_restrict: 2 });
      else if (data.config.list_max > 0)
        return this.setState({ list_value_restrict: 1 });
      else return this.setState({ list_value_restrict: 0 });
    }
  }

  handleRecurseTree = (ele, key) => {
    let i = 0;

    const toBeTraversedArray= [ele];
    toBeTraversedArray.push(ele);  

    while (toBeTraversedArray.length > 0) {
      let currentNode = toBeTraversedArray.shift(); 

      currentNode.id = `${key}-${i}`;
      if (i === 1) currentNode.className = "annotation-content-block";
      i++;
      
      if (currentNode.childNodes.length > 0) {
        toBeTraversedArray.push(...currentNode.childNodes);
      }
    } 

    return ele;
  }

  addIdsToContentBlock = (html, key) => {
    let parser = new DOMParser();
    let htmlDoc = parser.parseFromString(html, 'text/html');
    const newEle = this.handleRecurseTree(htmlDoc.body, key);
    return newEle.outerHTML;
  }

  handleSave = () => {
    if (!this.state.checkKey && this.state.tableErrors === "") {
      const {
        dataAuto,
        dataVisibility,
        dataReadOnly,
        dataRequired,
        dataComment,
        list_min,
        list_max,
        outputFormat,
        timezone,
        notificationEmails,
        choiceAnswerOption,
        size,
        height,
        width,
        embedType,
        url,
        snippet,
      } = this.state;
      let data = {
        ...this.state,
        required: this.filterBoolean(dataRequired, FormRequiredStore.Statement),
        comment: this.filterBoolean(dataComment, FormRequiredStore.Statement),
        visibility: this.filterBoolean(
          dataVisibility,
          FormVisibilityStore.Statement
        ),
        readonly: this.filterBoolean(dataReadOnly, FormReadOnlyStore.Statement),
        autoAnswer: this.filterString(
          dataAuto,
          FormPredertermineStore.Statement
        ),
        notificationEmails: notificationEmails
          ? notificationEmails
          : {
              emailList: [],
              shouldNotify: false,
            },
        choiceAnswerOption: choiceAnswerOption ? choiceAnswerOption: "manually",
        list_min: this.filterListMin(),
        list_max: this.filterListMax(),
        outputFormat: this.state.outputFormat,
        timezone: this.state.timezone,
        contentBlockPlaceholders: [],
        valueColumnId: this.state.valueColumnId,
        labelColumnId: this.state.labelColumnId,
        databaseId: this.state.databaseId,
        columnId: this.state.columnId,
        size: size,
        height: height,
        width: width,
        embedType: embedType,
        url: url,
        snippet: snippet,
      };
      const type =  SequentialFormContainer.editTarget.type;

      // Clear database vals if not from database
      if (choiceAnswerOption !== FROM_DB) {
        data["databaseId"] = "";
        data["columnId"] = "";
        data["valueColumnId"] = "";
        data["labelColumnId"] = "";
      }

      // need to put a check on type so that Ids are added only for content block no for file preview urls.
      if (type==11 && this.state.answer_data_default) {
        const new_data_default = this.addIdsToContentBlock(this.state.answer_data_default, this.state.key);
        data["answer_data_default"] = new_data_default;
      }

      for (let i in data.answer_list) {
        if (!data.answer_list[i][0]) {
          data.answer_list[i][0] = data.answer_list[i][1];
        }
      }
      SequentialFormContainer.saveConfig(data);
      this.props.checkPlaceholderForm("onSave");
    }

    this.setState({ contentBlockPlaceholders: [] });
  };

  filterString = (ButtonGroup, Statement) => {
    let data = "";
    if (ButtonGroup[2].active) {
      data = Statement;
    } else if (ButtonGroup[0].active) {
      data = this.state.autoAnswer;
    }
    return data;
  };

  filterBoolean = (ButtonGroup, Statement) => {
    let data = false;
    if (ButtonGroup[2] && ButtonGroup[2].active) {
      data = Statement;
    } else if (ButtonGroup[0].active) {
      data = true;
    }
    return data;
  };
  filterListMin = () => {
    if (this.state.list_value_restrict != 0) {
      if (
        this.state.list_value_restrict == 2 ||
        this.state.list_value_restrict == 3
      )
        return this.state.list_min;
    } else {
      return 0;
    }
    return 0;
  };
  filterListMax = () => {
    if (this.state.list_value_restrict != 0) {
      if (
        this.state.list_value_restrict == 1 ||
        this.state.list_value_restrict == 3
      )
        return this.state.list_max;
    } else {
      return 0;
    }
    return 0;
  };
  handleAddpair = () => {
    this.setState((state) => {
      state.answer_list.push(["", ""]);
      return { answer_list: state.answer_list };
    });
  };
  handlePoppair = (index) => {
    this.setState((state) => {
      state.answer_list.splice(index, 1);
      return { answer_list: state.answer_list };
    });
  };
  handleAnswerpair = (index, type, value) => {
    let output = value;
    this.setState((state) => {
      state.answer_list[index][type] = output;
      return { answer_list: state.answer_list };
    });
  };
  handleReplaceAnswerList = (newAnsList) => {
    this.setState({ answer_list: newAnsList });
  };
  handleChange = (event) => {
    this.setState({ [event.target.value]: !this.state[event.target.value] });
  };
  handleChangeOutput(e) {
    this.setState({ outputFormat: e.target.value });
  }
  handleChangeTimezone(e) {
    this.setState({ timezone: e.target.value });
  }
  handleInputPretermine = (event) => {
    this.setState({
      autoAnswer: event.target.value,
    });
  };

  handleChangeEmail = (chip) => {
    let key = chip.key;

    if (chip.isNotPlaceholder !== true) {
      key = "${" + key + "}";
    }

    this.setState({
      notificationEmails: {
        ...this.state.notificationEmails,
        emailList: [...this.state.notificationEmails.emailList, key],
      },
    });
  };

  handleDeleteEmail = (index) => {
    if (this.state.notificationEmails.emailList) {
      this.state.notificationEmails.emailList.splice(index, 1);
      this.setState({
        notificationEmails: {
          ...this.state.notificationEmails,
          emailList: this.state.notificationEmails.emailList,
        },
      });
    }
  };

  handleShouldNotify = () => {
    this.setState({
      notificationEmails: {
        ...this.state.notificationEmails,
        shouldNotify: !this.state.notificationEmails.shouldNotify,
      },
    });
  };

  handleInputKeyBlur = (event) => {
    this.setState({
      key: event.target.value,
    });
    this.props.setInnerPlaceholders();
  };

  handleInputKey = (event) => {
    const pages = toJS(SequentialFormContainer.pages);
    const { id } = toJS(SequentialFormContainer.editTarget);

    const placeholderValue = replacePlaceholderValue(event.target.value);

    const value = this.checkKey(placeholderValue, id, pages);
    this.setState({
      checkKey: value[0],
      valueError: value[1],
      placeholderValue,
    });
    this.props.handleKeyChanges(value[0]);
  };

  checkKey = (placeholderValue, id, data) => {
    let checkKey = false;
    let errorMessage = "";
    if (placeholderValue.length == 0) {
      checkKey = true;
      errorMessage = "This field cannot be empty";
    } else if (placeholderValue.length > 0) {
      if (/^[a-zA-Z0-9_]+$/.test(placeholderValue) == false) {
        return [true, "No special characters or spaces allowed"];
      }
    } else {
      checkKey = false;
    }
    return [checkKey, errorMessage];
  };

  handleInput = (type, event) => {
    const value = event.target.value;
    this.setState({ [type]: value });
  };

  appendInputValue = (type, appendValue) => {
    let realValue = this.state[type] + "" + appendValue;
    this.setState({ [type]: realValue });

    return realValue;
  };

  onClickButtonGroup = (key, name) => {
    const {
      dataAuto,
      dataVisibility,
      dataReadOnly,
      dataRequired,
      dataComment,
    } = this.state;
    if (name == "dataAuto") {
      const dataAnswer = SequentialFormContainer.changeButtonGroup(
        dataAuto,
        key
      );
      this.setState({
        dataAuto: dataAnswer,
        autoAnswer: "",
      });
    }
    if (name == "dataVisibility") {
      const dataAnswer = SequentialFormContainer.changeButtonGroup(
        dataVisibility,
        key
      );
      this.setState({
        dataVisibility: dataAnswer,
      });
    }
    if (name == "dataReadOnly") {
      const dataAnswer = SequentialFormContainer.changeButtonGroup(
        dataReadOnly,
        key
      );
      this.setState({
        dataReadOnly: dataAnswer,
      });
    }
    if (name == "dataRequired") {
      const dataAnswer = SequentialFormContainer.changeButtonGroup(
        dataRequired,
        key
      );
      this.setState({
        dataRequired: dataAnswer,
      });
    }

    if (name == "dataComment") {
      const dataAnswer = SequentialFormContainer.changeButtonGroup(
        dataComment,
        key
      );
      this.setState({
        dataComment: dataAnswer,
        notificationEmails: {
          emailList: [],
          shouldNotify: false,
        },
      });
    }
  };

  searchable = (value) => {
    let placeholders = {};
    let availablePlaceholders = [];
    if (value !== "") {
      let data = this.props.availablePlaceholders[0].placeholders.filter(
        (placeholder) => placeholder.key.includes(value)
      );
      placeholders["placeholders"] = data;
      availablePlaceholders.push(placeholders);
    } else {
      availablePlaceholders = this.props.availablePlaceholders;
    }
    this.setState({
      availablePlaceholders: availablePlaceholders,
    });
  };

  render() {
    const {
      dataAuto,
      dataVisibility,
      dataReadOnly,
      dataRequired,
      dataComment,
      comment,
      required,
      readonly,
      visibility,
      autoAnswer,
      outputFormat,
      file_type,
      notificationEmails,
    } = this.state;
    const { type } = SequentialFormContainer.editTarget;
    const target = SequentialFormContainer.editTarget;
    const theme = createTheme({
      palette: {
        primary: { 500: "#2196F3" },
      },
      typography: { useNextVariants: true },
    });
    const { classes } = this.props;
    return (
      <div style={{ overflow: "hidden" }}>
        <Snackbar
          open={this.state.showDynamicFormError}
          onClose={() => {
            this.setState({ showDynamicFormError: false });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          className={"red-snackbar"}
          autoHideDuration={10000}
          children={
            <Grid container item direction={"row"} alignItems={"center"}>
              Turn on "Allow placeholders in question text" in the component
              settings
              <IconButton
                fontSize={"inherit"}
                onClick={() => {
                  this.setState({ showDynamicFormError: false });
                }}
                size="large"
              >
                <Close className={"close-snackbar"} />
              </IconButton>
            </Grid>
          }
        />
        {type != 16 && (
          <>
            <Grid
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <Grid item>
                <p style={{ marginRight: "16px" }}>Placeholder</p>{" "}
              </Grid>

              <Grid item xs>
                <TextFieldUI
                  id="w86-placeholder-textfield"
                  size={"small"}
                  key={this.state.key}
                  defaultValue={this.state.key}
                  value={this.state.placeholderValue}
                  error={this.state.checkKey ? true : false}
                  style={{ width: "100%" }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{"${"}</InputAdornment>,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Grid container horizontal="center" vertical="center">
                                <Grid item className={classes.centerGrid}>
                                    {"}"}
                                    {this.state.checkKey && <Icon className="fa fa-exclamation-circle" style={{ color: "#f44336", marginLeft: "4px" }} />}
                                </Grid>
                            </Grid>
                        </InputAdornment>
                    ),
                  }}
                  helperText={
                    this.state.checkKey ? this.state.valueError : null
                  }
                  variant="outlined"
                  onChange={this.handleInputKey}
                  onBlur={this.handleInputKeyBlur}
                />
              </Grid>
              <Grid item xs={1}>
                <Tooltips
                  Title={"This will identity the data output of this question."}
                  Children={
                    <i className="material-icons custom-icon-btn">
                      help_outline
                    </i>
                  }
                />
              </Grid>
            </Grid>
            {/* QUESTION TEXT */}
            {type !== 11 && (
              <div style={{ marginBottom: 16 }}>
                {this.state.question_text_default !== null && (
                  <div className="mention-editor-container">
                    <span className="mention-editor-label">Question</span>
                    <FormMentionEditor
                      style={{
                        width: "100%",
                        marginBottom: 16,
                        verticalAlign: "top",
                        minHeight: "16px",
                      }}
                      contentBlock={false}
                      assignTask={true}
                      availablePlaceholders={this.props.availablePlaceholders}
                      text={
                        this.state.question_text_default
                          ? this.state.question_text_default
                          : ""
                      }
                      placeholder={
                        type != 11 ? "Enter your question" : "Enter Heading"
                      }
                      onChange={(value) => {
                        this.setState({ ["question_text_default"]: value });
                      }}
                      onFocus={(e) => {}}
                      ref={this.questionMentionInstance}
                      hideScrollBar={true}
                      {...(this.props.availablePlaceholders != null && {
                        availablePlaceholders: this.props.availablePlaceholders,
                      })}
                    />
                  </div>
                )}
              </div>
            )}
            {/* QUESTION DESCRIPTION */}
            {type !== 11 && (
              <div>
                {this.state.question_description_default !== null && (
                  <div className="mention-editor-container">
                    <span className="mention-editor-label">Description</span>
                    <FormMentionEditor
                      style={{
                        width: "100%",
                        marginBottom: 16,
                        verticalAlign: "top",
                        minHeight: "16px",
                      }}
                      availablePlaceholders={this.props.availablePlaceholders}
                      text={this.state.question_description_default}
                      placeholder={
                        type != 11
                          ? "Enter a question description"
                          : "Enter Sub-heading"
                      }
                      onChange={(value) => {
                        this.setState({
                          ["question_description_default"]: value,
                        });
                      }}
                      onFocus={(e) => {}}
                      hideScrollBar={true}
                      ref={this.questionDescnMentionInstance}
                      contentBlock={false}
                      assignTask={true}
                      {...(this.props.availablePlaceholders != null && {
                        availablePlaceholders: this.props.availablePlaceholders,
                      })}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {/* CONTENT BLOCK */}
        {type == 11 && this.state.answer_data_default !== null && (
          <div>
            <div className="mention-editor-container">
              <FormMentionEditor
                availablePlaceholders={this.props.availablePlaceholders}
                text={this.state.answer_data_default}
                onChange={(html) =>
                  this.setState({
                    answer_data_default: html,
                  })
                }
                placeholder={""}
                disableTooltip
                contentStyles={{
                  border: "1px solid rgba(0, 0, 0, 0.16)",
                  boxShadow: "none",
                  height: "auto",
                  minHeight: "360px",
                  padding: "8px",
                  minWidth: "100%",
                }}
                onFocus={(e) => {}}
                ref={this.questionMentionInstance}
                editorFocus={() => {
                  this.setState({ focus: "editor" });
                }}
                contentBlock={true}
                hideScrollBar={true}
                assignTask={true}
                {...(this.props.availablePlaceholders != null && {
                  availablePlaceholders: this.props.availablePlaceholders,
                })}
              />
            </div>
          </div>
        )}
        {/* LIST QUESTION TYPE */}
        {type == 12 && (
          <Grid container direction="column">
            <p>Control the number of entries to the list</p>
            <Grid container>
              <Select
                className="select-list"
                variant="outlined"
                value={this.state.list_value_restrict}
                onChange={(event) => {
                  this.setState({ list_value_restrict: event.target.value });
                }}
              >
                <MenuItem value={0}>No limit</MenuItem>
                <MenuItem value={1}>Set maximum</MenuItem>
                <MenuItem value={2}>Set minimum</MenuItem>
                <MenuItem value={3}>Set maximum and minimum</MenuItem>
              </Select>
              {this.state.list_value_restrict == 0 ? (
                " "
              ) : this.state.list_value_restrict == 1 ? (
                <Grid container direction="column">
                  <p>Maximum number of values that can be entered:</p>
                  <TextFieldUI
                    className={"numberInput"}
                    placeholder={"Enter a number"}
                    value={this.state.list_max}
                    onChange={(event) => {
                      this.setState({ list_max: event.target.value });
                    }}
                    type="number"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.formAreaInput },
                      inputProps: { min: 2, max: 100 },
                    }}
                  />
                </Grid>
              ) : this.state.list_value_restrict == 2 ? (
                <Grid container direction="column">
                  <p>
                    The user must enter a minimum of this many entries to the
                    list:
                  </p>
                  <TextFieldUI
                    className={"numberInput"}
                    placeholder={"Enter a number"}
                    value={this.state.list_min}
                    onChange={(event) => {
                      this.setState({ list_min: event.target.value });
                    }}
                    type="number"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.formAreaInput },
                      inputProps: { min: 2, max: 100 },
                    }}
                  />
                </Grid>
              ) : this.state.list_value_restrict == 3 ? (
                <Grid container direction="column">
                  <p>
                    The user must enter a minimum of this many entries to the
                    list:
                  </p>
                  <TextFieldUI
                    className={"numberInput"}
                    placeholder={"Enter a number"}
                    value={this.state.list_min}
                    onChange={(event) => {
                      this.setState({ list_min: event.target.value });
                    }}
                    type="number"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.formAreaInput },
                      inputProps: { min: 2, max: 100 },
                    }}
                  />
                  <p>
                    The user can only enter a maximum of this many entries to
                    the list:
                  </p>
                  <TextFieldUI
                    className={"numberInput"}
                    placeholder={"Enter a number"}
                    value={this.state.list_max}
                    onChange={(event) => {
                      this.setState({ list_max: event.target.value });
                    }}
                    type="number"
                    variant="outlined"
                    InputProps={{
                      classes: { input: classes.formAreaInput },
                      inputProps: { min: 2, max: 100 },
                    }}
                  />
                </Grid>
              ) : (
                " "
              )}
            </Grid>
          </Grid>
        )}
        {type == 14 && (
          <div>
            <div className={classes.filePreviewURL}>URL of file to preview</div>
            <div>
              {this.state.question_text_default !== null && (
                <FormMentionEditor
                  style={{
                    width: "100%",
                    marginBottom: 16,
                    verticalAlign: "top",
                    minHeight: "16px",
                  }}
                  availablePlaceholders={this.props.availablePlaceholders}
                  contentBlock={false}
                  assignTask={true}
                  text={
                    this.state.answer_data_default
                      ? this.state.answer_data_default
                      : ""
                  }
                  placeholder="Insert URL of the file to preview"
                  onChange={(value) => {
                    this.setState({ answer_data_default: value });
                  }}
                  onFocus={(e) => {}}
                  ref={this.questionMentionInstance}
                  hideScrollBar={true}
                  {...(this.props.availablePlaceholders != null && {
                    availablePlaceholders: this.props.availablePlaceholders,
                  })}
                />
              )}
            </div>
            <div className={classes.filePreviewURL}>Select file type</div>
            <div>
              <Select
                onChange={(e) => {
                  this.setState({
                    file_type: e.target.value,
                  });
                }}
                value={file_type}
                className={"selection"}
                style={{ width: "100%" }}
              >
                <MenuItem value={"pdf"}>.pdf</MenuItem>
                <MenuItem value={"docx"}>.docx</MenuItem>
                <MenuItem value={"pptx"}>.pptx</MenuItem>
                <MenuItem value={"csv"}>.csv</MenuItem>
                <MenuItem value={"xlsx"}>.xlsx</MenuItem>
                <MenuItem value={"png"}>.png, .jpeg, .gif, .bmp</MenuItem>
              </Select>
            </div>
          </div>
        )}
        {type == 7 ? (
          <Calendar
            preview={false}
            outputFormat={this.state.outputFormat}
            timezone={this.state.timezone}
            handleChangeOutput={this.handleChangeOutput}
            handleChangeTimezone={this.handleChangeTimezone}
          />
        ) : (
          ""
        )}
        {type == 15 ? (
          <Table
            preview={false}
            numRows={this.state.numRows}
            setNumRows={(rowCount) => this.setState({ numRows: rowCount })}
            exportType={this.state.exportType}
            setExportType={(exportType) =>
              this.setState({ exportType: exportType })
            }
            columns={this.state.columns}
            setColumns={(updatedColumns) =>
              this.setState({ columns: updatedColumns })
            }
            rowLabels={this.state.rowLabels}
            setRowLabels={updatedRowLabels => 
              this.setState({ rowLabels: updatedRowLabels})}
            tableErrors={this.state.tableErrors}
            setTableErrors={(tableErrs) =>
              this.setState({ tableErrors: tableErrs })
            }
            handleKeyChanges={this.props.handleKeyChanges}
            tableCells={this.state.tableCells}
            setTableCells={(updatedCells) =>
              this.setState({ tableCells: updatedCells })
            }
            availablePlaceholders={this.props.availablePlaceholders}
          />
        ) : (
          ""
        )}

        {type == 19 && (
          <RepeatableSection 
            preview={false}
            numRows={this.state.numRows}
            setNumRows={(rowCount) => this.setState({ numRows: rowCount })}
            exportType={this.state.exportType}
            setExportType={(exportType) =>
              this.setState({ exportType: exportType })
            }
            exportFormat={this.state.exportFormat}
            setExportFormat={(exportFormat) =>
              this.setState({ exportFormat: exportFormat })
            }
            columns={this.state.columns}
            setColumns={(updatedColumns) =>
              this.setState({ columns: updatedColumns })
            }
            rowLabels={this.state.rowLabels}
            setRowLabels={(updatedRowLabels) =>
              this.setState({ rowLabels: updatedRowLabels })
            }
            tableErrors={this.state.tableErrors}
            setTableErrors={(tableErrs) =>
              this.setState({ tableErrors: tableErrs })
            }
            handleKeyChanges={this.props.handleKeyChanges}
            tableCells={this.state.tableCells}
            setTableCells={(updatedCells) =>
              this.setState({ tableCells: updatedCells })
            }
            availablePlaceholders={this.props.availablePlaceholders}
          />
        )}

        {type == 4 || type == 5 || type == 10 ? (
          <ChoiceQuestion
            answer_list={this.state.answer_list}
            handleAddpair={() => {
              this.handleAddpair();
            }}
            handlePoppair={(index) => {
              this.handlePoppair(index);
            }}
            handleAnswerpair={(key, type, value) => {
              this.handleAnswerpair(key, type, value);
            }}
            handleReplaceAnswerList={(newAnsList) => {
              this.handleReplaceAnswerList(newAnsList);
            }}
            type={type}
            databaseId={SequentialFormContainer.editTarget.config.databaseId}
            choiceAnswerOption={
              SequentialFormContainer.editTarget.config.choiceAnswerOption
            }
            handleChangeChoiceAnswerOption={(newOption) => {
              this.setState({ choiceAnswerOption: newOption });
            }}
            availablePlaceholders={this.props.availablePlaceholders}
            columnId={SequentialFormContainer.editTarget.config.columnId}
            changeColumn={(columnId) => {
              this.setState({ columnId: columnId });
            }}
            labelColumnId={
              SequentialFormContainer.editTarget.config.labelColumnId
                ? SequentialFormContainer.editTarget.config.labelColumnId
                : null
            }
            changeColumnLabel={(columnId) => {
              this.setState({ labelColumnId: columnId, columnId: "" });
            }}
            changeColumnValue={(columnId) => {
              this.setState({ valueColumnId: columnId, columnId: "" });
            }}
            valueColumnId={
              SequentialFormContainer.editTarget.config.valueColumnId
                ? SequentialFormContainer.editTarget.config.valueColumnId
                : null
            }
            changeDb={(databaseId) => {
              this.setState({ databaseId: databaseId });
            }}
            resetAnswerList={() => {
              this.setState({ answer_list: [["", ""]] });
            }}
            removeDbInfo={() => {
              this.setState({ databaseId: "", columnId: "" });
              SequentialFormContainer.editTarget.config.databaseId = "";
              SequentialFormContainer.editTarget.config.columnId = "";
            }}
          />
        ) : (
          ""
        )}{" "}
        {/*VIDEO EMBED*/}
        {type === 16 && (
          <VideoEmbed
            target={target}
            setTarget={(updatedTarget) => {
              this.setState({
                size: updatedTarget.config.size,
                height: updatedTarget.config.height,
                width: updatedTarget.config.width,
                embedType: updatedTarget.config.embedType,
                url: updatedTarget.config.url,
                snippet: updatedTarget.config.snippet,
              });
            }}
          />
        )} {/*RICH TEXT EDITOR*/}
        {type === 17 && (
            <div>
              <div className="rich-text-prefill-container">
                <FormMentionEditor
                    style={{
                      width: "100%",
                      marginBottom: 16,
                      verticalAlign: "top",
                    }}
                    assignTask={true}
                    contentBlock={true}
                    text={this.state.rte_answer_data_default}
                    onChange={(html) => {
                      this.setState({
                        rte_answer_data_default: html,
                      });
                    }}
                    disableTooltip
                    onFocus={(e) => {}}
                    ref={this.questionMentionInstance}
                    contentStyles={{
                      border: "1px solid rgba(0, 0, 0, 0.16)",
                      borderRadius: "4px",
                      boxShadow: "none",
                      padding: 0,
                      minWidth: "100%",
                      minHeight:"500px"

                    }}
                    hideScrollBar={true}
                    {...(this.props.availablePlaceholders != null && {
                      availablePlaceholders: this.props.availablePlaceholders,
                    })}
                />
              </div>
            </div>
        )}
        {type !== 16 && (
          <>
            <Grid
              className={"advanced"}
              container
              item
              xs={12}
              direction={"row"}
              alignItems={"center"}
            >
              <p
                onClick={() => {
                  this.setState({ advancedExpand: !this.state.advancedExpand });
                }}
                className="s-text custom-icon-btn narrow"
              >
                Advanced Settings
                {this.state.advancedExpand ? (
                  <i className="material-icons">keyboard_arrow_up</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_down</i>
                )}
              </p>

              <Tooltips
                Title={
                  "Control features including whether the question must be answered, prefill the question answer, question visibility and whether it is read-only."
                }
                Children={
                  <i className="material-icons custom-icon-btn narrowIcon">
                    help_outline
                  </i>
                }
              />
            </Grid>

            {this.state.advancedExpand && (
              <div>
                {type !== 17 && (<div>
                  <Grid
                  container
                  item
                  xs={12}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <p className={"bold margin"}>Comments:</p>
                  <p className={"s-text"}>
                    Allow users to enter a comment for this question
                  </p>
                </Grid>
                <ButtonGroup
                  onClickButton={(key) => {
                    this.onClickButtonGroup(key, "dataComment");
                  }}
                  size="small"
                  data={dataComment}
                />
                {dataComment &&
                  dataComment[0].active &&
                  notificationEmails != null && (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"flex-start"}
                      marginTop={"16px"}
                    >
                      <Grid item xs={12}>
                        <Checkbox
                          checked={notificationEmails.shouldNotify}
                          onClick={this.handleShouldNotify}
                          className="notify-email-checkbox"
                          size="medium"
                        />
                        <span className="comment-email-text">
                          Send email when a new comment is posted
                        </span>
                      </Grid>
                      {notificationEmails.shouldNotify && (
                        <Grid item xs={12} marginTop={"8px"}>
                          <ChipInput
                            inputValue={notificationEmails.emailList}
                            fullWidth={true}
                            placeholder={"Type in an email"}
                            onPlaceholderSelected={this.handleChangeEmail}
                            onDelete={(index) => {
                              this.handleDeleteEmail(index);
                            }}
                            placeholders={this.props.availablePlaceholders}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </div>)}
                {type != 11 && type != 14 && type != 15 && type != 19 && (
                  <div>
                    <Grid xs={12} direction={"row"} container item>
                      <p className={"bold margin"}>Required: </p>
                      <p className={"s-text"}>
                        control whether this question must be answered
                      </p>
                    </Grid>
                    <ButtonGroup
                      onClickButton={(key) => {
                        this.onClickButtonGroup(key, "dataRequired");
                      }}
                      size="small"
                      data={dataRequired}
                    />
                    {dataRequired[2].active && (
                      <FormRequired
                        required={required}
                        availablePlaceholders={this.props.availablePlaceholders}
                      />
                    )}
                  </div>
                )}
                {type != 11 &&
                  type != 6 &&
                  type != 13 &&
                  type != 15 && type != 17 && type != 19 && (
                    <div>
                      <Grid xs={12} container item direction={"row"}>
                        <p className={"bold margin"}>Prefill answer: </p>
                        <p className={"s-text"}>
                          prefill the answer to this question based on a default
                          value or conditional statement
                        </p>
                      </Grid>
                      <ButtonGroup
                        onClickButton={(key) => {
                          this.onClickButtonGroup(key, "dataAuto");
                        }}
                        size="small"
                        data={dataAuto}
                      />
                      {dataAuto && dataAuto[0].active && (
                        <>
                          <div className="mention-editor-container">
                            <span className="mention-editor-label">
                              Prefilled value
                            </span>
                            <FormMentionEditor
                              style={{
                                width: "100%",
                                marginBottom: 8,
                                marginTop: 16,
                                verticalAlign: "top",
                                minHeight: "16px",
                              }}
                              contentBlock={false}
                              assignTask={true}
                              availablePlaceholders={
                                this.props.availablePlaceholders
                              }
                              text={
                                this.state.autoAnswer
                                  ? this.state.autoAnswer
                                  : ""
                              }
                              onChange={(value) => {
                                this.setState({
                                  autoAnswer: value,
                                });
                              }}
                              onFocus={(e) => {}}
                              ref={this.inputPretermineInstance}
                              hideScrollBar={true}
                              {...(this.props.availablePlaceholders != null && {
                                availablePlaceholders: this.props
                                  .availablePlaceholders,
                              })}
                            />
                          {(type === 4 || type === 5 || type === 10) && (
                            <Grid
                              container
                              item
                              direction={"row"}
                              alignItems={"center"}
                              className={classes.warningGrid}
                            >
                              <ErrorIcon
                               className={classes.warningIcon}
                              />
                              <p className={classes.warningText}>
                                Prefilled value must be an exact match with the
                                Answer Value, or the Answer Option if Answer
                                Value has not been set
                              </p>
                            </Grid>
                          )}
                          </div>
                        </>
                      )}
                      {type == 12 && dataAuto && dataAuto[0] && dataAuto[0].active
                        ? <p className="s-text m-0" style = {{ fontSize:"12px" }} >Enter values separated by a comma</p> 
                        : ""
                      }
                      {dataAuto[2].active && (
                        <>
                          <FormPredertermine
                            autoAnswer={autoAnswer}
                            availablePlaceholders={
                              this.props.availablePlaceholders
                            }
                          />
                          {type == 12 && <p className="s-text m-0" style = {{ fontSize:"12px" }} >Enter values separated by a comma</p>}
                        </>
                      )}
                    </div>
                  )}
                <div>
                  <div>
                    <Grid container item xs={12} direction={"row"}>
                      <p className={"bold margin"}>Visibility: </p>
                      <p className={"s-text"}>
                        control whether this question is shown or hidden
                      </p>
                    </Grid>
                    <ButtonGroup
                      onClickButton={(key) => {
                        this.onClickButtonGroup(key, "dataVisibility");
                      }}
                      size="small"
                      data={dataVisibility}
                    />
                  </div>
                  {dataVisibility[2].active && (
                    <FormVisibility
                      visibility={visibility}
                      availablePlaceholders={this.props.availablePlaceholders}
                    />
                  )}
                </div>
                {type != 11 && type != 6 && type != 14 && (
                  <div>
                    <div>
                      <Grid xs={12} container item direction={"row"}>
                        <p className={"bold margin"}>Read Only: </p>
                        <p className={"s-text"}>
                          control whether this question is read-only
                        </p>
                      </Grid>
                      <ButtonGroup
                        onClickButton={(key) => {
                          this.onClickButtonGroup(key, "dataReadOnly");
                        }}
                        size="small"
                        data={dataReadOnly}
                      />
                    </div>
                    {dataReadOnly[2].active && (
                      <FormReadOnly
                        readonly={readonly}
                        availablePlaceholders={this.props.availablePlaceholders}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

ComponentConfig.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(observer(ComponentConfig));
