import "../../App.css";
import clsx from "clsx";
import "../../index.css";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  MenuItem,
  Tooltip,
  TextField,
  InputAdornment,
  Link as MLink,
  ClickAwayListener
} from "@mui/material";
import {
  AccountBox,
  ChevronLeft,
  ExitToApp,
  Menu,
  ShopRounded,
  AssignmentTurnedIn,
  AlternateEmail,
  LinkedIn,
  Twitter,
  FileCopy,
  Business,
  Face,
  Help,
  CardGiftcard,
  MeetingRoom,
  FlagCircle
} from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import Tour from "reactour";
import {
  WelcomePageTourPrivateSteps,
  WelcomePageTourSteps,
} from "../../utils/ProductTour";
import AppRoutes from "../../Routes";
import config from "../../config";
import AppBar from "vardogyir-ui/Appbar";
import Button from "@mui/material/Button";
import DrawerItem from "vardogyir-ui/DrawerItem";
import HelpDialog from "./HelpDialog";
import { Link } from "react-router-dom";
import { DragDropContext } from "react-dnd";
import Amplify, { Auth } from "aws-amplify";
import { observer } from "mobx-react";
import Dialog from "@mui/material/Dialog";
import SvgIcon from "@mui/material/SvgIcon";
import HTML5Backend from "react-dnd-html5-backend";
import { send_request } from "../../utils/Request";
import React from "react";
import { CustomDialog } from "./Dialog";
import TaskNotificationsBadge from "./TaskNotificationsBadge";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { withStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import { getMarketplaceDialogOptions } from "../../utils/MarketplaceDialog";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HistoryIcon from "@mui/icons-material/History";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { loadAuth2, loadGapiInsideDOM } from "gapi-script";
import aws_config from "../../auth/awsConfig";
import FreeTrialBanner from "./FreeTrialBanner";
import W86Backdrop from "./W86Backdrop";
import { CustomSignIn } from "./CustomSignIn";
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
  Outlet,
  Navigate,
} from "react-router-dom";
import { getCurrentUser, signOut, fetchUserAttributes } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
const appIdDriftChat = config.DRIFCHAT;

const drawerWidth = 204;

const styles = (theme) => ({
  Button: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "0px",
    color: "white",
    border: "1px solid white",
    backgroundColor: "#2196F3",
    "&:hover": {
      color: "#BBDEFB",
      border: "1px solid #BBDEFB",
      backgroundColor: "#2196F3",
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  Menu: {
    marginLeft: "10px",
    marginRight: "10px",
    color: "#2196F3",
    minWidth: "48px",
    border: "1px solid #2196F3",
    backgroundColor: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#2196F3",
    },
  },
  appBar: {
    height: 64,
    left: 77,
    backgroundColor: "#2196F3",
    width: `calc(100%  - 77px)`,
    zIndex: theme.zIndex.drawer + 1,
  },

  appBarShift: {
    left: 0,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px )`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    top: "auto",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 48,
    top: "auto",
  },
  materialIcons: {
    width: 20,
    height: 20,
    padding: 0,
    margin: 0,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 12px",
    ...theme.mixins.toolbar,
  },
  main: {
    alignItems: "center",
  },
  content: {
    width: "calc(100% - 48px)",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },

  drawerHeader: {
    alignItems: "center",
    padding: "0 ",
    height: "40px",
  },
  listItem: {
    height: 50,
  },
  table: {
    width: "100%",
  },

  titleMargin: {
    marginLeft: "16px",
  },
  linkToUpgrade: {
    fontWeight: "bold",
    textDecoration: "none",
  },
  sidebarMenuLinks: {
    textDecoration: "none",
    margin: 0,
    padding: 0,
  },
  closeUpgrading: {
    color: "#ffffff",
    cursor: "pointer",
    textAlign: "center",
  },
  listMenuFree: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 32,
  },
  listMenu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
});

function ReportIcon(props) {
  return (
    <SvgIcon style={{ marginLeft: 0 }} {...props}>
      <g clip-path="url(#clip0)">
        <path d="M8.31079 14.5458H5.59375C5.33484 14.5458 5.125 14.7556 5.125 15.0145V19.5312C5.125 19.7902 5.33484 20 5.59375 20H8.31091C8.5697 20 8.77966 19.7902 8.77966 19.5312V15.0145C8.77954 14.7556 8.5697 14.5458 8.31079 14.5458V14.5458Z" />
        <path d="M13.353 12.0211H10.6359C10.377 12.0211 10.1671 12.231 10.1671 12.4899V19.5312C10.1671 19.7902 10.377 20 10.6359 20H13.353C13.6118 20 13.8218 19.7902 13.8218 19.5312V12.4899C13.8218 12.231 13.6118 12.0211 13.353 12.0211V12.0211Z" />
        <path d="M18.395 8.2081H15.678C15.4191 8.2081 15.2092 8.41794 15.2092 8.67685V19.5312C15.2092 19.7901 15.4191 20 15.678 20H18.395C18.6539 20 18.8638 19.7901 18.8638 19.5312V8.67685C18.8638 8.41794 18.6539 8.2081 18.395 8.2081V8.2081Z" />
        <path d="M5.62471 9.8933C5.69819 9.8933 5.77278 9.87596 5.84248 9.83934L10.7382 7.26488L12.687 8.27513C12.8267 8.34764 12.9936 8.34508 13.1311 8.2683L17.4725 5.84569L17.3055 6.28636C17.2137 6.52843 17.3355 6.79906 17.5776 6.89086C17.6323 6.91149 17.6884 6.92137 17.7436 6.92137C17.9328 6.92137 18.111 6.80602 18.1821 6.61864L18.8027 4.98131C18.8178 4.94164 18.8265 4.9005 18.8304 4.859C18.8332 4.83251 18.8337 4.80541 18.8316 4.77892C18.827 4.71202 18.8085 4.64513 18.7738 4.58275C18.6938 4.43944 18.5499 4.35472 18.3975 4.34386L16.7181 4.00914C16.4643 3.95848 16.2174 4.12328 16.1668 4.37718C16.1162 4.63109 16.281 4.87792 16.5349 4.92858L17.0192 5.02501L12.8945 7.32677L10.9525 6.31993C10.8164 6.24938 10.6542 6.24987 10.5186 6.32128L5.40608 9.00951C5.17695 9.13011 5.08894 9.41344 5.20943 9.64257C5.29329 9.80211 5.45613 9.8933 5.62471 9.8933V9.8933Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

function FormIcon(props) {
  return (
    <SvgIcon style={{ marginLeft: 0 }} {...props}>
      <path d="M13.5 5H7.5C6.675 5 6.0075 5.675 6.0075 6.5L6 18.5C6 19.325 6.6675 20 7.4925 20H16.5C17.325 20 18 19.325 18 18.5V9.5L13.5 5ZM15 17H9V15.5H15V17ZM15 14H9V12.5H15V14ZM12.75 10.25V6.125L16.875 10.25H12.75Z" />
    </SvgIcon>
  );
}

function ProjectIcon(props) {
  return (
    <SvgIcon style={{ marginLeft: 0 }} {...props}>
      <path d="M4 11H9V5H4V11ZM4 18H9V12H4V18ZM10 18H15V12H10V18ZM16 18H21V12H16V18ZM10 11H15V5H10V11ZM16 5V11H21V5H16Z" />
    </SvgIcon>
  );
}

function WorkflowIcon(props) {
  return (
    <SvgIcon
      viewBox="-2 -2 20 20"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <path d="M0.125 7.5C0.125 6.7125 0.396874 6.04687 0.940624 5.50312C1.48437 4.95937 2.15 4.6875 2.9375 4.6875C3.5875 4.6875 4.15937 4.88125 4.65312 5.26875C5.14687 5.65625 5.48125 6.15 5.65625 6.75L7.25 6.75L7.25 2.25L10.25 2.25L10.25 0.375L15.5 0.375L15.5 5.625L10.25 5.625L10.25 3.75L8.75 3.75L8.75 11.25L10.3437 11.25C10.5187 10.65 10.8531 10.1562 11.3469 9.76875C11.8406 9.38125 12.4125 9.1875 13.0625 9.1875C13.85 9.1875 14.5156 9.45937 15.0594 10.0031C15.6031 10.5469 15.875 11.2125 15.875 12C15.875 12.7875 15.6031 13.4531 15.0594 13.9969C14.5156 14.5406 13.85 14.8125 13.0625 14.8125C12.4125 14.8125 11.8406 14.6187 11.3469 14.2312C10.8531 13.8437 10.5187 13.35 10.3437 12.75L7.25 12.75L7.25 8.25L5.65625 8.25C5.48125 8.85 5.14687 9.34375 4.65312 9.73125C4.15937 10.1187 3.5875 10.3125 2.9375 10.3125C2.15 10.3125 1.48437 10.0406 0.940624 9.49687C0.396874 8.95312 0.125 8.2875 0.125 7.5ZM11.75 12C11.75 12.3625 11.8781 12.6719 12.1344 12.9281C12.3906 13.1844 12.7 13.3125 13.0625 13.3125C13.425 13.3125 13.7344 13.1844 13.9906 12.9281C14.2469 12.6719 14.375 12.3625 14.375 12C14.375 11.6375 14.2469 11.3281 13.9906 11.0719C13.7344 10.8156 13.425 10.6875 13.0625 10.6875C12.7 10.6875 12.3906 10.8156 12.1344 11.0719C11.8781 11.3281 11.75 11.6375 11.75 12ZM11.75 1.875L11.75 4.125L14 4.125L14 1.875L11.75 1.875ZM1.625 7.5C1.625 7.8625 1.75312 8.17187 2.00937 8.42812C2.26562 8.68437 2.575 8.8125 2.9375 8.8125C3.3 8.8125 3.60937 8.68437 3.86562 8.42812C4.12187 8.17187 4.25 7.8625 4.25 7.5C4.25 7.1375 4.12187 6.82812 3.86562 6.57187C3.60937 6.31562 3.3 6.1875 2.9375 6.1875C2.575 6.1875 2.26562 6.31562 2.00937 6.57187C1.75312 6.82812 1.625 7.1375 1.625 7.5Z" />
    </SvgIcon>
  );
}

function DatabasesIcon(props) {
  return (
    <SvgIcon
      viewBox="0 -960 960 960"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-479q89 0 179-25.5T760-679q-11-29-100.5-55T480-760q-91 0-178.5 25.5T200-679q14 30 101.5 55T480-599Zm0 199q42 0 81-4t74.5-11.5q35.5-7.5 67-18.5t57.5-25v-120q-26 14-57.5 25t-67 18.5Q600-528 561-524t-81 4q-42 0-82-4t-75.5-11.5Q287-543 256-554t-56-25v120q25 14 56 25t66.5 18.5Q358-408 398-404t82 4Zm0 200q46 0 93.5-7t87.5-18.5q40-11.5 67-26t32-29.5v-98q-26 14-57.5 25t-67 18.5Q600-328 561-324t-81 4q-42 0-82-4t-75.5-11.5Q287-343 256-354t-56-25v99q5 15 31.5 29t66.5 25.5q40 11.5 88 18.5t94 7Z" />
    </SvgIcon>
  );
}

 export function CredentialsIcon(props) {
  return (
    <SvgIcon
      viewBox="0 -960 960 960"
      preserveAspectRatio="xMinYMin meet"
      {...props}
    >
      <path d="M280-400q-33 0-56.5-23.5T200-480q0-33 23.5-56.5T280-560q33 0 56.5 23.5T360-480q0 33-23.5 56.5T280-400Zm0 160q-100 0-170-70T40-480q0-100 70-170t170-70q67 0 121.5 33t86.5 87h352l120 120-180 180-80-60-80 60-85-60h-47q-32 54-86.5 87T280-240Zm0-80q56 0 98.5-34t56.5-86h125l58 41 82-61 71 55 75-75-40-40H435q-14-52-56.5-86T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Z" />{" "}
    </SvgIcon>
  );
}

class InternalApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      username: "",
      roleAdministrator: false,
      roleEditor: false,
      rolePrivateViewer: false,
      timezone: "",
      tier: "",
      orgName: "",
      openHelp: false,
      userId: "",
      userEmail: "",
      userClient: "",
      pageStatus: "home",
      accountStatus: "",
      openExtraSessions: false,
      userDisplayName: "",
      drawerIndex: undefined,
      clientId: null,
      isAIEnabled: false,
      firstLogin: false,
    };
    this.updateUsername = this.updateUsername.bind(this);
  }

  async componentDidMount() {
    ProjectStore.closeProjectPageTour();
    ProjectStore.closeCanvasPageTour();
    ProjectStore.closeMarketplacePageTour();
    ProjectStore.closePortalPageTour();
    ProjectStore.closeCredentialPageTour();
    ProjectStore.closeRepositoryPageTour();
    
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      const currentUser = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      
      let email = userAttributes.email || idToken.payload.email;

      try {
        const checkUserResponse = await send_request(
          `authz-service/check-user-verified-and-completed`,
          { email },
          "",
          "POST"
        );
        if (checkUserResponse && checkUserResponse.data) {
          if (checkUserResponse.data.isCompleted) {
            this.setState({ firstLogin: false });
          } else {
            this.setState({ firstLogin: true });
          }
        } else {
          console.log(
            "Failed to check user status:",
            checkUserResponse ? checkUserResponse.status : "No response"
          );
        }
      } catch (error) {
        console.log("An error occurred while checking user status:", error);
      }
    } catch (error) {
      console.log("Error fetching user info:", error);
    }

    const res = await send_request(
      `authz-service/has_ai_onboarding_access`,
      null,
      null,
      "GET"
    );
    const isAIEnabled = res ? res.data : false;

    const json = await send_request(`authz-service/user-info`, "", {});
    if (json && json.data) {
      this.setState({
        roleAdministrator: json.data.roleAdministrator,
        roleEditor: json.data.roleEditor,
        rolePrivateViewer: json.data.rolePrivateViewer,
        tier: json.data.client.tier,
        orgName: json.data.client.clientName ? json.data.client.clientName : "",
        userName: json.data.userName ? json.data.userName : "",
        userId: json.data.userId ? json.data.userId : "",
        userEmail: json.data.email ? json.data.email : "",
        userDisplayName: json.data.displayName ? json.data.displayName : "",
        clientId: json.data.client.clientId ? json.data.client.clientId : null,
        isAIEnabled: isAIEnabled,
      });
      ProjectStore.state.timezone = json.data.timezone;
      ProjectStore.state.tier = json.data.client.tier;
      ProjectStore.state.hasBanner =
        json.data.client.tier === "FREE_TRIAL" ||
        json.data.client.tier === "FREE";
      ProjectStore.state.orgName = json.data.client.clientName;
      ProjectStore.state.clientId = json.data.client.clientId;
      ProjectStore.state.userName = json.data.userName;
      ProjectStore.state.isAIEnabled = isAIEnabled;
      window.Intercom("boot", {
        app_id: "gdyabshp",
        email: json.data.email,
        user_id: json.data.userId,
        name: json.data.userName,
        company: json.data.client.clientName,
        tier: json.data.client.tier,
        timezone: json.data.timezone,
        custom_launcher_selector: "#chat_with_someone",
      });

      fetchUserAttributes().then((attributes) => {
        if (attributes) {
          if (attributes.identities) {
            this.setState({
              accountStatus: "GOOGLE",
            });
          } else {
            this.setState({
              accountStatus: "USERNAME",
            });
          }
        }
      });

      if (ProjectStore.state.timezone == null)
        ProjectStore.state.timezone = "Australia/Hobart";
    } else {
      ProjectStore.state.timezone = "Australia/Hobart";
    }

    // Retrieves the marketplace categories and creates a mapping (CategoryId -> Category Name)
    // and stores the categories as a list
    const marketPlaceCategoriesRes = await send_request(
      "project-service/template/projectCategories",
      "",
      {},
      "GET"
    );

    let categoryMapping = {};
    let sortedCategories = getMarketplaceDialogOptions(
      marketPlaceCategoriesRes.data
    );

    const compare = (a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 0;
    };

    sortedCategories.sort(compare);

    sortedCategories.forEach((category) => {
      categoryMapping[category.categoryId] = category.displayName;
    });

    ProjectStore.state.workflowCategories = sortedCategories;

    ProjectStore.state.workflowCategoriesMapping = categoryMapping;
  }

  updateUsername(newUsername) {
    this.setState({ username: newUsername });
  }

  async onSignOut() {
    window.Intercom("shutdown");

    try {
      // Sign out from Amplify
      await signOut();

      // Load the auth2 library
      await new Promise((resolve, reject) => {
        window.gapi.load('auth2', resolve);
      });

      // Initialize the auth2 library
      const auth2 = await window.gapi.auth2.init({
        client_id: aws_config.gg_client_id,
      });

      // Sign out from Google
      if (auth2.isSignedIn.get()) {
        await auth2.signOut();
        console.log("User signed out from Google.");
      }

      // Redirect to home page
      window.location.pathname = "/";
    } catch (err) {
      console.error("Error signing out:", err);
      // Redirect to home page even if there's an error
      window.location.pathname = "/";
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  findSelected = (index) => {
    if (this.state.drawerIndex == undefined) this.findSelectedThroughUrl();
    if (typeof index == "number") {
      if (this.state.drawerIndex == index) return true;
      else return false;
    }
  };

  handleClick = () => {
    this.setState({ openHelp: true });
  };

  getSessions = async () => {
    const getReferral = await send_request(
      "authz-service/get_referral",
      "",
      {},
      "GET"
    )
      .then((response) => {
        if (response && response.data) {
          this.setState({ referral: response.data });
          this.setState({ openExtraSessions: true });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  findSelectedThroughUrl = () => {
    let pathname = window.location.pathname.split("/")[1];

    switch (pathname) {
      case "":
        this.setState({ drawerIndex: 0 });
        break;
      case "project":
        this.setState({ drawerIndex: 2 });
        break;
      case "databases":
        this.setState({ drawerIndex: 3 });
        break;
      case "task":
        this.setState({ drawerIndex: 4 });
        break;
      case "credential_store":
        this.setState({ drawerIndex: 5 });
        break;
      case "user_management":
        this.setState({ drawerIndex: 6 });
        break;
      case "portal":
        this.setState({ drawerIndex: 7 });
        break;
      case "marketplace":
        this.setState({ drawerIndex: 8 });
        break;
      case "repository":
        this.setState({ drawerIndex: 9 });
        break;
      case "achievements":
        this.setState({ drawerIndex: 10 });
        break;
    }
  };
  render() {
    const { classes, dialogTour } = this.props;
    const { roleAdministrator, rolePrivateViewer, roleEditor } = this.state;

    const encoded = encodeURIComponent("http://app.workflow86.com/signUp");

    const emailBody = `Our%20team%20uses%20%40Workflow86%20to%20document%2C%20automate%20and%20manage%20our%20operations%20playbooks%20and%20business%20processes.%0D%0A%0D%0ASign%20up%20to%20%40Workflow86%20using%20this%20invite%20code%20and%20get%20%2B25%20actions%20per%20month%20for%20free%3A%0D%0A%0D%0A${this.state.referral}%0D%0A%0D%0Awww.workflow86.com`;

    let pathname = window.location.pathname.split("/")[1];

    if (window.location.pathname.split("/")[2] != "canvas_preview") {
      if (this.props.authState === "signedIn" && !this.state.firstLogin) {
        return (
          <Grid container direction={"column"}>
            <W86Backdrop />
            <HelpDialog
              open={this.state.openHelp}
              close={() => {
                this.setState({ openHelp: false });
              }}
            />
            <CustomDialog
              isOpen={this.state.openExtraSessions}
              size={"sm"}
              title={"🎁 Invite others and get free actions"}
              contents={
                <>
                  <p className={"s-text"}>
                    Share the code below when you invite others to create a
                    Workflow86 account. When someone signs up and uses your
                    invite code, you will both get 25 actions per month added to
                    your accounts.{" "}
                  </p>
                  <p className={"bold"}>Your invite code</p>
                  <TextField
                    value={this.state.referral}
                    variant={"outlined"}
                    size={"small"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard text={this.state.referral}>
                            <FileCopy className="custom-icon-btn" />
                          </CopyToClipboard>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <p>Share your invite code via</p>
                  <MLink
                    className="resp-sharing-button__link"
                    href={`mailto:?subject=${encodeURIComponent(
                      "💌 Sharing our invite code for Workflow86"
                    )}&body=${emailBody}`}
                    target="_self"
                    onClick={() => {
                      this.setState({ openLink: false });
                    }}
                  >
                    <Button className="resp-sharing-button resp-sharing-button--email resp-sharing-button--large">
                      <div
                        aria-hidden="true"
                        className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                      >
                        <AlternateEmail />
                      </div>
                      EMail
                    </Button>
                  </MLink>
                  <MLink
                    className="resp-sharing-button__link"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encoded}`}
                    target="_blank"
                    onClick={() => {
                      this.setState({ openLink: false });
                    }}
                  >
                    <Button className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--large">
                      <div
                        aria-hidden="true"
                        className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                      >
                        <LinkedIn className={"share-icon-white"} />
                      </div>
                      LinkedIn
                    </Button>
                  </MLink>
                  <MLink
                    className="resp-sharing-button__link"
                    href={`https://twitter.com/intent/tweet/?text=${encodeURIComponent(
                      `🔥 Sign up to @Workflow86 using this invite code and get +25 workflow sessions per month for free: ${this.state.referral}`
                    )}&url=${encoded}`}
                    target="_blank"
                    onClick={() => {
                      this.setState({ openLink: false });
                    }}
                  >
                    <Button className="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--large">
                      <div
                        aria-hidden="true"
                        className="resp-sharing-button__icon resp-sharing-button__icon--solid"
                      >
                        <Twitter className={"share-icon-white"} />
                      </div>
                      Twitter
                    </Button>
                  </MLink>
                </>
              }
              buttons={
                <>
                  <Button
                    variant={"outlined"}
                    color={"info"}
                    onClick={() => {
                      this.setState({ openExtraSessions: false });
                    }}
                  >
                    Back
                  </Button>
                </>
              }
            />
            <CustomDialog
              fullWidth={true}
              maxWidth={"sm"}
              isOpen={dialogTour}
              title={"👋 Welcome to Workflow86"}
              contents={
                <>
                  <img
                    src="/images/first-login.png"
                    className="dialogImageSize"
                  ></img>
                  <p className="textDialogContent">
                    Hi! It looks like it is your first time logging in. We’d
                    like to show you a quick tour so you can get familiar with
                    the platform. Click Start Tour to begin. You can also skip
                    the tour for now, and run it at a later time.
                  </p>
                </>
              }
              buttons={
                <>
                  <Button
                    variant={"outlined"}
                    color={"info"}
                    onClick={(event) => {
                      this.props.setFirstLoginState(false);
                    }}
                  >
                    {" "}
                    SKIP
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                      ProjectStore.openWelcomePageTour();
                      this.props.setFirstLoginState(false);
                    }}
                  >
                    {" "}
                    START TOUR
                  </Button>
                </>
              }
            />
            <Tour
              steps={
                !roleEditor && !roleAdministrator && rolePrivateViewer
                  ? WelcomePageTourPrivateSteps
                  : WelcomePageTourSteps
              }
              isOpen={ProjectStore.isWelcomePageTour}
              onRequestClose={ProjectStore.closeWelcomePageTour}
            />

            {this.state.tier == "FREE" ? (
              <Grid
                container
                className={"freeTierTopBar"}
                alignItems={"center"}
              >
                <Grid item xs={11} className={"freeTierTopBarText"}>
                  You are currently on the free plan. Run more actions and
                  unlock premium features by{" "}
                  <Link className={classes.linkToUpgrade} to="/set_plan">
                    upgrading now 🚀
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={1}
                  onClick={() => {
                    this.setState({ tier: "" });
                    ProjectStore.state.hasBanner = false;
                  }}
                  className={classes.closeUpgrading}
                >
                  x
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            {this.state.tier == "FREE_TRIAL" && (
              <FreeTrialBanner
                classes={classes}
                closeBanner={() => {
                  this.setState({ tier: "" }); //
                  ProjectStore.state.hasBanner = false;
                }}
              />
            )}

            <Grid
              item
              xs={12}
              className={
                this.state.tier &&
                (this.state.tier == "FREE" || this.state.tier === "FREE_TRIAL")
                  ? "freeTierMarginTop"
                  : ""
              }
              style={{ display: "flex" }}
            >
              <Grid style={{ width: 48 }}>
                <ClickAwayListener
                  onClickAway={() => {
                    // Close on click away if the drawer is open
                    if (!this.state.open) return;
                    this.setState({ open: false });
                  }}
                >
                  <Drawer
                    variant="permanent"
                    className="drawerMain"
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                      }),
                    }}
                    open={this.state.open}
                    PaperProps={{
                      className: "drawerItem",
                      sx: {
                        "& .MuiButtonBase-root": {
                          paddingLeft: 0,
                          paddingRight: 0,
                        },
                        fontSize: 12,
                      },
                    }}
                    id="main_menu_drawer"
                  >
                    <div className={classes.toolbar}>
                      {this.state.open ? (
                        <IconButton onClick={this.handleDrawerClose}>
                          <ChevronLeft />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="Open drawer"
                          onClick={this.handleDrawerOpen}
                        >
                          <Menu />
                        </IconButton>
                      )}
                    </div>
                    <Divider />
                    <List
                      className={
                        this.state.tier == "FREE"
                          ? classes.listMenuFree
                          : classes.listMenu
                      }
                    >
                      <Grid>
                        {(roleEditor || rolePrivateViewer) && (
                          <Tooltip placement={"right"} title={"Your home page"}>
                            <Link
                              to="/"
                              data-tut="reactour__home"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                onClick={() => {
                                  this.setState({ drawerIndex: 0 });
                                }}
                                selected={this.findSelected(0)}
                                Title={"Home"}
                                icon={
                                  <HomeOutlinedIcon className="material-icons" />
                                }
                                id="main_menu_drawer_home"
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleEditor && (
                          <Tooltip
                            placement={"right"}
                            title={
                              "All of your published and unpublished workflows"
                            }
                          >
                            <Link
                              to="/project"
                              data-tut="reactour__project"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                onClick={() => {
                                  this.setState({ drawerIndex: 2 });

                                  if (ProjectStore.isWelcomePageTour) {
                                    ProjectStore.openProjectPageTour();
                                  }
                                }}
                                selected={this.findSelected(2)}
                                Title={"Workflows"}
                                icon={
                                  <WorkflowIcon className="material-icons" />
                                }
                                className="list-item"
                              />
                            </Link>
                          </Tooltip>
                        )}
                        <Tooltip
                          placement={"right"}
                          title={"Manage your assigned tasks"}
                        >
                          <Link
                            to="/task"
                            data-tut="reactour__mytasks"
                            className={classes.sidebarMenuLinks}
                            id="my_tasks_page"
                          >
                            <DrawerItem
                              selected={this.findSelected(4)}
                              Title={"Task Inbox"}
                              icon={<TaskNotificationsBadge />}
                              onClick={() => {
                                this.setState({ drawerIndex: 4 });
                              }}
                            />
                          </Link>
                        </Tooltip>
                        {rolePrivateViewer && (
                          <Tooltip
                            placement={"right"}
                            title={
                              "View all published forms from your workflows"
                            }
                          >
                            <Link
                              to="/portal"
                              data-tut="reactour__portal"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(7)}
                                onClick={() => {
                                  if (ProjectStore.isWelcomePageTour) {
                                    ProjectStore.openPortalPageTour();
                                  }
                                  this.setState({ drawerIndex: 7 });
                                }}
                                Title={"Forms"}
                                icon={
                                  <BallotOutlinedIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {(roleEditor || roleAdministrator) && (
                          <Tooltip
                            placement={"right"}
                            title={"Access your built-in databases"}
                          >
                            <Link
                              to="/databases"
                              data-tut="reactour__databases"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(3)}
                                onClick={() => {
                                  this.setState({ drawerIndex: 3 });
                                }}
                                Title={"Databases"}
                                icon={
                                  <DatabasesIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {(roleEditor || roleAdministrator) && (
                          <Tooltip
                            placement={"right"}
                            title={
                              "View your securely stored credentials and secrets"
                            }
                          >
                            <Link
                              to="/credential_store"
                              // data-tut="reactour__user__management"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(5)}
                                onClick={() => {
                                  this.setState({ drawerIndex: 5 });
                                }}
                                Title={"Credentials"}
                                icon={
                                  <CredentialsIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleAdministrator && (
                          <Tooltip
                            placement={"right"}
                            title={
                              "Activity logs of events and actions across your account"
                            }
                          >
                            <Link
                              to="/activity"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                onClick={() => {
                                  this.setState({ drawerIndex: 16 });

                                  if (ProjectStore.isWelcomePageTour) {
                                    ProjectStore.openProjectPageTour();
                                  }
                                }}
                                selected={this.findSelected(16)}
                                Title={"Activity Logs"}
                                icon={
                                  <HistoryIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleAdministrator && (
                          <Tooltip
                            placement={"right"}
                            title={"Manage users in your account"}
                          >
                            <Link
                              to="/user_management"
                              data-tut="reactour__user__management"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(6)}
                                onClick={() => {
                                  this.setState({ drawerIndex: 6 });
                                }}
                                Title={"User Management"}
                                icon={
                                  <GroupsOutlinedIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleEditor && (
                          <Tooltip
                            placement="right"
                            title="View all shared workflows"
                          >
                            <Link
                              to="/repository"
                              className={classes.sidebarMenuLinks}
                              data-tut="reactour_repository"
                            >
                              <DrawerItem
                                selected={this.findSelected(9)}
                                onClick={() => {
                                  this.setState({ drawerIndex: 9 });
                                }}
                                Title="Shared Workflows"
                                icon={
                                  <ShareOutlinedIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleEditor && (
                          <Tooltip
                            placement={"right"}
                            title={
                              "Find and clone pre-built workflow templates"
                            }
                          >
                            <Link
                              to="/marketplace"
                              data-tut="reactour__marketplace"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(8)}
                                onClick={() => {
                                  if (
                                    ProjectStore.isWelcomePageTour ||
                                    ProjectStore.isCanvasPageTour
                                  ) {
                                    ProjectStore.openMarketplacePageTour();
                                  }
                                  this.setState({ drawerIndex: 8 });
                                }}
                                Title={"Templates"}
                                icon={
                                  <CollectionsBookmarkOutlinedIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        {roleEditor && (
                          <Tooltip
                            placement={"right"}
                            title={"View your achievements and perks"}
                          >
                            <Link
                              to="/achievements"
                              // data-tut="reactour__marketplace"
                              className={classes.sidebarMenuLinks}
                            >
                              <DrawerItem
                                selected={this.findSelected(10)}
                                onClick={() => {
                                  this.setState({ drawerIndex: 10 });
                                }}
                                Title={"Achievements"}
                                icon={
                                  <EmojiEventsOutlinedIcon className="material-icons" />
                                }
                              />
                            </Link>
                          </Tooltip>
                        )}
                        <Tooltip placement={"right"} title={"Invite codes"}>
                          <Link to={"#"} style={{ textDecoration: "none" }}>
                            <DrawerItem
                              index={11}
                              onClick={() => {
                                this.getSessions();
                                this.setState({ drawerIndex: 11 });
                              }}
                              selected={this.findSelected(11)}
                              Title={"Invite codes"}
                              icon={<CardGiftcard className="material-icons" />}
                            />
                          </Link>
                        </Tooltip>
                        <Tooltip placement={"right"} title={"Help"}>
                          <Link to={"#"} style={{ textDecoration: "none" }}>
                            <DrawerItem
                              index={12}
                              onClick={() => {
                                this.handleClick();
                                this.setState({ drawerIndex: 12 });
                              }}
                              selected={this.findSelected(12)}
                              Title={"Help"}
                              icon={
                                <HelpOutlineOutlinedIcon className="material-icons" />
                              }
                            />
                          </Link>
                        </Tooltip>
                      </Grid>
                      <Grid>
                        <Tooltip
                          placement={"right"}
                          title="Your user account settings"
                        >
                          <Link
                            to={"/profile"}
                            style={{ textDecoration: "none" }}
                          >
                            <DrawerItem
                              index={13}
                              onClick={() => {
                                this.setState({ drawerIndex: 13 });
                              }}
                              selected={this.findSelected(13)}
                              Title={this.state.userDisplayName}
                              icon={
                                <AccountBoxOutlinedIcon className="material-icons" />
                              }
                            />
                          </Link>
                        </Tooltip>
                        {roleAdministrator && (
                          <Tooltip
                            placement={"right"}
                            title="Your organization settings"
                          >
                            <Link
                              to={"/organization"}
                              style={{ textDecoration: "none" }}
                            >
                              <DrawerItem
                                index={14}
                                onClick={() => {
                                  this.setState({ drawerIndex: 14 });
                                }}
                                selected={this.findSelected(14)}
                                Title={this.state.orgName}
                                icon={<Business className="material-icons" />}
                              />
                            </Link>
                          </Tooltip>
                        )}
                        <Tooltip placement={"right"} title={"Log out"}>
                          <Link to={""} style={{ textDecoration: "none" }}>
                            <DrawerItem
                              index={15}
                              onClick={() => {
                                this.onSignOut();
                                this.setState({ drawerIndex: 15 });
                              }}
                              selected={this.findSelected(15)}
                              Title={"Log out"}
                              icon={<ExitToApp className="material-icons" />}
                            />
                          </Link>
                        </Tooltip>
                      </Grid>
                    </List>
                  </Drawer>
                </ClickAwayListener>
              </Grid>

              <main className={clsx(classes.content)}>
                <AppRoutes firstLogin={this.state.firstLogin} />
              </main>
            </Grid>
          </Grid>
        );
      } else if (this.state.firstLogin) {
        return (
          <Routes>
            <Route
              path="/"
              element={
                <CustomSignIn
                  authState={this.props.authState}
                  updateUsername={this.props.updateUsername}
                  onStateChange={this.props.onStateChange}
                  firstLogin={(val) => {
                    this.setState({ firstLogin: val });
                  }}
                />
              }
            />
          </Routes>
        );
      } else return null;
    } else {
      return (
        <div>
          <AppRoutes firstLogin={this.state.firstLogin} />
        </div>
      );
    }
  }
}
export default DragDropContext(HTML5Backend)(
  withStyles(styles)(observer(InternalApp))
);