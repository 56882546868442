import React, { useContext, useState, useEffect } from 'react';
import { Grid, CircularProgress } from "@mui/material";
import { CheckCircle, Error } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { CanvasContext } from "../../../../WorkflowCanvas";

const AIDraft = styled("div")(({ background, border }) => ({
  background: background,
  border: "1px solid " + border,
  borderRadius: "4px",
  margin: "4px",
  fontSize: "11px",
  padding: "4px",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "20px",
  maxWidth: "fit-content",
}));

const StatusIcon = styled('div')({
  height: "18px",
  width: "auto",
});

const GreenStatusIcon = styled(StatusIcon)({
  color: "#55A77A",
});

const RedStatusIcon = styled(StatusIcon)({
  color: "#E10000",
});

const TO_DELETE_STATUS = "TO_DELETE";
const TO_ADD_STATUS = "TO_ADD";
const TO_UPDATE_STATUS = "TO_UPDATE";
const renderEditStatusIcon = (editStatus) => {
  switch (editStatus) {
    case TO_DELETE_STATUS:
      return <>❌ To be removed</>;
    case TO_ADD_STATUS:
      return <></>;
    case TO_UPDATE_STATUS:
      return (
          <>
            {" "}
            <img src="/svgs/cycle.svg" alt="Update icon" /> To be updated
          </>
      );
    default:
      return null;
  }
};

const AI_BUILD_GENERATED = "AI_BUILD_GENERATED";
const AI_EDIT_GENERATED = "AI_EDIT_GENERATED";
const AI_BUILD_ERROR = "AI_BUILD_ERROR";
const AI_BUILD_CANCELLED = "AI_BUILD_CANCELLED";
const AI_EDIT_ERROR = "AI_EDIT_ERROR";
const AI_EDIT_CANCELLED = "AI_EDIT_CANCELLED";
const ERROR_STATUS = "Error";
const IN_PROGRESS_STATUS = "In progress...";
const BUILDING = "Building...";
const COMPLETED_STATUS = "Completed";
const AIDraftStatusBanner = ({ componentId, editStatus, isTemp, data }) => {
  const { componentInfoHeader } = useContext(CanvasContext);
  const [componentInfoForNodeHeader, setComponentInfoForNodeHeader] = componentInfoHeader ? componentInfoHeader : useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const componentToRebuild = componentInfoForNodeHeader.find(
      (item) => item.componentId === componentId
    );

    if (componentToRebuild && componentToRebuild.status) {
      if (
        componentToRebuild.status === AI_BUILD_GENERATED ||
        componentToRebuild.status === AI_EDIT_GENERATED
      ) {
        setStatus(COMPLETED_STATUS);
        if (data?.description !== componentToRebuild.description) {
          data.description = componentToRebuild.description;
        }
      } else if (
        componentToRebuild.status === AI_BUILD_ERROR ||
        componentToRebuild.status === AI_BUILD_CANCELLED ||
        componentToRebuild.status === AI_EDIT_ERROR ||
        componentToRebuild.status === AI_EDIT_CANCELLED
      ) {
        setStatus(ERROR_STATUS);
      } else {
        setStatus(componentToRebuild.isEdit ? IN_PROGRESS_STATUS : BUILDING);
      }
    } else {
      setStatus(null);
    }
  }, [componentId, componentInfoForNodeHeader]);

  if (!status && !editStatus && !isTemp) return null;

  const getStatusColor = (status) => {
    if (status === ERROR_STATUS) return "#E10000";
    if (status === COMPLETED_STATUS) return "#55A77A";
    return "#757575";
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case COMPLETED_STATUS:
        return  (
        <GreenStatusIcon>
          <CheckCircle />
        </GreenStatusIcon>);
      case ERROR_STATUS:
        return (<RedStatusIcon>
          <Error />
        </RedStatusIcon>);
      case BUILDING:
      case IN_PROGRESS_STATUS:
        return (<CircularProgress size={14} thickness={4.4} />);
      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: "4px" }}>
      {isTemp && (
        <AIDraft
          background={"#F2E5FA"}
          border={"#8000CE"}
        >
          📝 AI draft
        </AIDraft>
      )}

      {status && (
        <AIDraft
          background={"#FFFFFF"}
          border={getStatusColor(status)}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>{getStatusIcon(status)}</Grid>
            <Grid item>{status}</Grid>
          </Grid>
        </AIDraft>
      )}

      {editStatus && (
        <AIDraft
          background={"#FFFFFF"}
          border={"#000"}
        >
          <Grid container alignItems="center" spacing={1} wrap="nowrap">
            <Grid item>{renderEditStatusIcon(editStatus)}</Grid>
          </Grid>
        </AIDraft>
      )}
    </div>
  );
};

export default AIDraftStatusBanner;