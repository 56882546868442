const config = {
  VERSION: "0.0.1",
  API_ADDRESS: process.env.REACT_APP_ENV === 'local'
    ? "http://localhost:5001/client"
    : "https://services.workflow86.com",
  TEMPLATES_API_ADDRESS:
    "https://sjmz3f1vp4.execute-api.ap-southeast-2.amazonaws.com/prod/templates",
};

config.REPORT = {
  CREATE_URL:
    "https://awe2dvmr2b.execute-api.ap-southeast-2.amazonaws.com/dev/project-service/write/new",
  QUERY_URL: "http://localhost:5100/template/query/id",
  UPDATE_URL:
    "https://awe2dvmr2b.execute-api.ap-southeast-2.amazonaws.com/dev/project-service/write/update",
};

config.PROJECT = {
  CREATE_URL: "http://localhost:5432/write/new",
  UPDATE_URL: "http://localhost:5432/write/update",
  QUERY_ID_URL: "http://localhost:5432/query/id",
  QUERY_CLIENT_URL: "http://localhost:5432/query/client",
};

config.FORM = {
  QUERY_URL: "http://localhost:5001/client/form",
  // FORM_FRONT_END: "https://form.w86dev.click/",
  FORM_FRONT_END: "https://form.workflow86.com/",
};

config.PIPLINE = {
  QUERY_URL: "http://localhost:5001/client/pipeline",
  CREATE_URL: "http://localhost:5001/client/pipeline",
};

config.EMAIL = {
  QUERY_URL: "http://localhost:51007/template/query/id",
  CREATE_URL: "http://localhost:51007/template/write/upsert",
};

config.API_EXPORT = {
  QUERY_URL: "http://localhost:5002/template/query/id",
  CREATE_URL: "http://localhost:5002/template/write/upsert",
};

config.FILE_SERVICE = {
  QUERY_URL: "https://file.workflow86.com/file/get",
};

config.HEADER = {
  "Content-Type": "application/json",
};

//config.PUBLISHABLEKEY = "pk_test_xU4umroBmBvnsqJ13Q9JfOTb00oH3HvkNT"
config.PUBLISHABLEKEY = "pk_live_CSn7ahwRzAqZNfkIuGAGcXHJ00pTU61FeP";
config.CAPTCHA = "6LfrsPgZAAAAAANMXn-uTu9SSlepaQJ3amx_7Zb9";
//config.CAPTCHA = "6LfqxAYaAAAAAL1YXWbIUYAls97jB0DNbInRlNpc"
config.DRIFCHAT = "9i6beu92wen7";
config.HJID = "2209168";
config.HJSV = "6";

//*************************TYPEFORM CREDENTIALS*************************
//localhost:3000 set up
// config.TYPEFORM_CLIENT_ID = "GJW4ESUgidnJjrXqtdrwkW6PeVqCE8LXMw3qVGhaK8AH"
// config.TYPEFORM_REDIRECT_URL = "http://localhost:3000/callback/callback"

//dev setup
// config.TYPEFORM_CLIENT_ID = "BjxNQnzJaUfgAYyFiTH5MG9posTnHipD4smHCrLQAtnC"
// config.TYPEFORM_REDIRECT_URL = "https://d2z23dxxyevteb.cloudfront.net/callback/callback"

config.WEBHOOK_URL = "https://webhook.workflow86.com/webhook";

config.WEBHOOK_ENDPOINT = {
  SAMPLE: "https://webhook.workflow86.com/webhook/catch-sample",
  PRODUCTION: "https://webhook.workflow86.com/webhook"
}

//prod setup will go here
config.TYPEFORM_CLIENT_ID = "3sGAQhbiE8QPQRGqHfAGDFKGSPqbGXv8VbvNYCfx2w8U";
config.TYPEFORM_REDIRECT_URL = "https://app.workflow86.com/callback/callback";

config.APP_URL = "https://app.workflow86.com";

//local
// config.DASHBOARD_SERVICE_WEBSOCKET = "http://localhost:10000/ws";
//dev
// config.DASHBOARD_SERVICE_WEBSOCKET =
//   "http://w86-dev-socket-253149366.ap-southeast-2.elb.amazonaws.com:10000/ws";
// prod
config.DASHBOARD_SERVICE_WEBSOCKET = "https://dashboard.workflow86.com:10000/ws";

// ------- Pane widths configs ------- //
config.PANE_WIDTH = "40%";
config.FORM_PANE_WIDTH = "57%";
config.FULL_SCREEN_WIDTH = "97.5%";
config.AI_WORKFLOW_BUILDER_PANE_WIDTH = "33%";
config.AI_COMPONENT_BUILDER_PANE_WIDTH = "25%";
config.TEST_PANE_WIDTH = "25%";
config.SESSION_HISTORY_PANEL_WIDTH = "275px";
config.COMPONENT_BUILDER_BASE_WIDTH = "47.5%";
config.COMPONENT_BUILDER_EXPANDED_WIDTH = "72.75%";
config.TRANSLATION_BOTH_PANELS_OPEN = "translateX(-105.25%)";
config.TRANSLATION_BOTH_PANELS_OPEN_NOT_FORM = "translateX(-125%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN = "translateX(-34%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN_FORM = "translateX(-43%)";
config.TRANSLATION_ONLY_TEST_PANEL_OPEN_NOT_FORM = "translateX(-62%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN = "translateX(-34%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_FORM = "translateX(-43.5%)";
config.TRANSLATION_ONLY_BUILDER_PANEL_OPEN_NOT_FORM = "translateX(-62.5%)";

// Progress view
config.SESSION_LOGS_LIST_WIDTH = "400px";
config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH_IN_SPLIT_VIEW = "500px";
config.SESSION_LOGS_COMPONENT_INFO_PANE_WIDTH = "40%";

config.AI_WORKFLOW_BUILDER_PANE_MAX_WIDTH_IN_PX = 480;
config.AI_ENABLED_CLIENTS = [191576,788,75694,9,228612,409181,844,422408,710417,823009,2,883223];
config.FORM_PANE_WITH_PANES_OPEN="82%";

config.OAUTH = {
  CALLBACK_URL: process.env.REACT_APP_ENV === 'local'
    ? "https://faea-103-100-226-144.ngrok-free.app/oauth/callback"
    : "https://oauth.workflow86.com/callback"
};

export default config;
//form to point previews at
config.PREVIEW_FORM_URL = "https://form.workflow86.com/form/25d1cb58-8c01-4e97-bfb9-de18c43233c0"