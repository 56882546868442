import React, {Component} from 'react';
import {toJS} from "mobx";
import PlaceholderJson from "../../Component/PlaceholderJson";
import Button from "@mui/material/Button";
import APIResReqStore from "../APIResReqStore";
import {FormControl, Grid, TextField} from "@mui/material";
import AlertMessage from './AlertMessage';
import "ace-builds/src-noconflict/theme-idle_fingers";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
class Finish extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkKey: false
    };
  }

  render() {
    const {responseData, requestData} = toJS(APIResReqStore);
    return (
      <Grid container className="textAlignLeft">
        <AlertMessage
          message={"Set up has been completed. A summary of this component is detailed below."}
          alertType="success"
        />
        <Grid
          item
          xs={12}
          className="webhook-title" style={{margin: "16px 0"}}>
          <span>The following API request will be sent </span>
          <span onClick={() => this.props.onChangeState(0, 0)}
                style={{color: "#2196F3", fontWeight: "normal", cursor: "pointer"}}>edit request
          </span>
        </Grid>

        <div hidden={APIResReqStore.requestData.setupMode !== "MANUAL"}>
          <Grid
            item
            xs={12}
          >
            <span>Method: </span><span>{requestData.request_type}</span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{marginTop: 8}}>
            <span>Endpoint URL: </span><span>{requestData.endpoint_url}</span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{marginTop: 8}}>
            <span>Headers: </span>
            {APIResReqStore.requestData.headers.length > 0 ? <CustomHeader stepStatus={this.props.stepStatus}/> : null}
          </Grid>
          <Grid
            item
            xs={12}
            style={{margin: "16px 0"}}>
            <span>Body: </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{width: "100%"}}>
            <div id="aceditor" style={{width: "100%", height: "700px"}}>
              <AceEditor
                mode="javascript"
                theme="github"
                width="100%"
                height="100%"
                showPrintMargin={false}
                showGutter={true}
                wrapEnabled={true}
                highlightActiveLine={true}
                value={APIResReqStore.requestData.body}
                fontSize={16}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2
                }}/>
            </div>
          </Grid>
        </div>

        <div hidden={APIResReqStore.requestData.setupMode !== "CURL"} style={{width: "100%"}}>
          <Grid
            container
            style={{
              padding: "12px 24px 24px 24px",
              background: "white"
            }}
            className="api-editor-container"
          >
            <Grid item xs={12} style={{width: "100%"}}>
              <FormControl fullWidth style={{marginTop: "24px"}}>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: "8px",
                    fontSize: "14px",
                    fontFamily: "Open Sans",
                    fontWeight: "normal",
                    lineHeight: "19px",
                    color: "#868686"
                  }}
                >
                  cURL
                </Grid>
                <Grid item xs={12} style={{width: "100%"}}>
                  <div id="aceditor" style={{width: "100%", height: "200px"}}>
                    <AceEditor
                      mode="javascript"
                      theme="github"
                      width="100%"
                      height="100%"
                      showPrintMargin={false}
                      showGutter={true}
                      wrapEnabled={true}
                      highlightActiveLine={true}
                      value={APIResReqStore.requestData.curl}
                      fontSize={16}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                      editorFocus={() => {
                        this.setState({focus: "editor"});
                      }}/>
                  </div>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <Grid
          item
          xs={12}
          className="webhook-title">
          <span>The following placeholders will be generated from the incoming data </span>
          <span
            onClick={() => this.props.onChangeState(2, 2)}
            style={{color: "#2196F3", fontWeight: "normal", cursor: "pointer"}}>edit placeholders
          </span>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <PlaceholderJson
            componentStore={APIResReqStore}
            data={toJS(APIResReqStore).responseData}
            placeholderJson={responseData.samplePlaceholders}/>
        </Grid>
        <Grid
          item
          xs={12}
          className="dialogContentRight">
          <Grid
            item
            xs={12}
          >
            <Button
              onClick={() => {
                this.props.onChangeState(2, 2)
              }}
              className="btnSpace"
              variant={"outlined"}
            >
              {" "}
              BACK
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const CustomHeader = (props) => {
  return (
    <Grid>
      <Grid xs={12} container spacing={3} direction="row">
        <Grid item xs={4}>
          <p style={{color: "black"}}>Key</p>
        </Grid>
        <Grid item xs={4}>
          <p style={{color: "black"}} className="textRightLabelJson">
            Value
          </p>
        </Grid>
      </Grid>
      {APIResReqStore.requestData.headers.map((h) => (
        <Grid
          container
          alignItems="center"
          direction="row"
          style={{marginTop: "8px"}}
        >
          <Grid item xs={4}>
            <TextField
              value={h.header_name}
              placeholder="Key"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(event) => {
                h.header_name = event.target.value;
              }}
              disabled={props.stepStatus === "finish" ? true : false}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={h.header_value}
              placeholder="Value"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(event) => {
                h.header_value = event.target.value;
              }}
              disabled={props.stepStatus === "finish" ? true : false}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default Finish;